export const QuizIfExpressionType = Object.freeze({
    EQ: "eq",
    NEQ: "neq",
    IN: "in",
    NIN: "nin",
    NUMBER_LT: "number_lt",
    NUMBER_LTE: "number_lte",
    NUMBER_EQ: "number_eq",
    NUMBER_GTE: "number_gte",
    NUMBER_GT: "number_gt"
});

export const QuizIfConditionEntityType = Object.freeze({
    EXP_GROUP: "exp_group",
    EXPRESSION: "expression"
});
export const QuizIfConditionGroupType = Object.freeze({
    AND: "and",
    OR: "or"
});

export const QuizGroup = Object.freeze({
    DIAGNOSIS: "diagnosis",
    DIAGNOSIS_PRIVATE: "diagnosis_private",
    STANDALONE: "standalone",
    THERAPIST_RECRUITMENT: "therapist_recruitment",
    DEVELOPMENT: "development"
});
export const QuizGroupLabel = Object.freeze({
    [QuizGroup.DIAGNOSIS]: "Diagnostyka",
    [QuizGroup.DIAGNOSIS_PRIVATE]: "Diagnostyka (niepubliczne)",
    [QuizGroup.STANDALONE]: "Quiz niezależny",
    [QuizGroup.THERAPIST_RECRUITMENT]: "Rekrutacja terapeutów",
    [QuizGroup.DEVELOPMENT]: "SANDBOX"
});
export const QuizGroupSelectItems = Object.values(QuizGroup).map(it => ({
    text: QuizGroupLabel[it],
    value: it
}));
export const QuizGroupColor = Object.freeze({
    [QuizGroup.DIAGNOSIS]: "primary",
    [QuizGroup.DIAGNOSIS_PRIVATE]: "primary darken-2",
    [QuizGroup.STANDALONE]: "grey",
    [QuizGroup.THERAPIST_RECRUITMENT]: "indigo",
    [QuizGroup.DEVELOPMENT]: "secondary"
});

export const QuizLayout = Object.freeze({
    QL1: "ql1",
    QL2: "ql2"
});
export const QuizLayoutLabel = Object.freeze({
    [QuizLayout.QL1]: "QL1",
    [QuizLayout.QL2]: "QL2"
});
export const QuizLayoutSelectItems = Object.values(QuizLayout).map(it => ({
    text: QuizLayoutLabel[it],
    value: it
}));
