<template>
    <div style="width: 100%">
        <!-- KOMPONENT POZWALA NA ŁATWE WYSZUKIWANIE UŻYTKOWNIKÓW Z SYSTEMU -->
        <v-autocomplete
            :value="value"
            @input="
                _ => {
                    $emit('input', _);
                    $emit('change', _);
                }
            "
            outlined
            :loading="loading"
            :items="users"
            :search-input.sync="search_string"
            :hide-no-data="!searched_at_all"
            item-value="_id"
            :item-text="getItemText"
            :label="label"
            cache-items
            :clearable="clearable"
            :disabled="disabled"
            :dense="dense"
            :hide-details="hideDetails"
            background-color="white"
        >
            <template v-slot:item="{ item }">
                <div class="py-2">
                    <UserDetailedTile
                        :id="item._id"
                        :profile-image="item.profile_image ? item.profile_image.url_sm : ''"
                        :phone="{
                            phone_cc: item.phone_cc,
                            phone: item.phone
                        }"
                        :name="`${item.first_name} ${item.last_name}`"
                        :email="item.email"
                        :avatar-size="24"
                        :copyable="false"
                    />
                </div>
            </template>
            <template v-slot:selection="{ attrs, item }">
                <div class="pt-1">
                    <v-chip
                        v-bind="attrs"
                        color="grey lighten-3"
                        class="ma-0 mr-2"
                        :small="dense"
                    >
                        <v-avatar
                            left
                            v-if="
                                item.profile_image &&
                                item.profile_image.url_xs &&
                                item.profile_image.url_xs != ''
                            "
                        >
                            <v-img :src="item.profile_image.url_xs"></v-img>
                        </v-avatar>
                        {{ item.first_name }} {{ item.last_name ? item.last_name : "" }}
                        <span
                            class="ml-1"
                            v-if="$store.state.auth.user && $store.state.auth.user._id == item._id"
                        >
                            (Ty)
                        </span>
                    </v-chip>
                </div>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
export default {
    props: {
        filterStatus: {
            type: String,
            default: ""
        },
        filterRole: {
            type: String,
            default: ""
        },
        value: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: "Wyszukaj użytkownika"
        },
        disabled: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        hideDetails: {
            type: [String, Boolean],
            default: false
        },
        clearable: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            loading: false,
            users: [],
            search_string: "",
            searched_at_all: false,
            search_debouncer: null
        };
    },

    watch: {
        search_string() {
            this.debounceSearch();
        },
        value: {
            immediate: true,
            handler() {
                if (this.$store.state.initial_layout_set && !this.searched_at_all) {
                    this.handleValue();
                }
            }
        }
    },

    methods: {
        debounceSearch() {
            if (this.search_debouncer != null) clearTimeout(this.search_debouncer);
            this.search_debouncer = setTimeout(this.doSearch, 250);
        },
        getItemText(item) {
            return `${item.first_name} ${item.last_name} ${item.email} ${item.phone}`;
        },
        async doSearch(force = false) {
            if (this.loading) return;
            if (!force && (!this.search_string || this.search_string.length < 3)) return;
            this.loading = true;

            try {
                const r = await this.$axios.$get(
                    `/users/?filter_search=${this.search_string}${
                        this.filterRole != "" ? "&filter_role=" + this.filterRole : ""
                    }${this.filterStatus != "" ? "&filter_status=" + this.filterStatus : ""}`
                );

                this.users = r.users;
                this.searched_at_all = true;
            } catch (err) {
                console.error(err);
            }

            this.loading = false;
            this.search_debouncer = null;
        },

        async handleValue() {
            if (this.value != "" && this.value != "null" && this.value != null) {
                await this.doSearch(true);
                const ix = this.users.findIndex(item => item.id == this.value);
                if (ix == -1) {
                    // jeżeli nie pobrało akurat tego jednego usera, to pobieramy go sami
                    try {
                        const r = await this.$axios.$get(`/users/${this.value}`);
                        this.users.push(r.user);
                    } catch (err) {}
                }
            }
        }
    }
};
</script>
