export function getPossibleLogicExpressionOutputs(le_string) {
    const OUTPUTS = [];
    let cwo = {};

    try {
        cwo = JSON.parse(le_string);
    } catch (err) {
        console.error(err);
    }

    function go() {
        if (cwo.then !== undefined) OUTPUTS.push(cwo.then);
        if (cwo.else !== undefined) {
            if (typeof cwo.else === "string") {
                OUTPUTS.push(cwo.else);
            } else {
                cwo = cwo.else;
                go();
            }
        }
    }
    go();

    return OUTPUTS;
}
