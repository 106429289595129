<template>
    <div class="content-block-aslform">
        <v-row dense>
            <v-col
                cols="12"
                md="6"
            >
                <v-select
                    outlined
                    label="Ikona"
                    v-model="lv.icon"
                    :items="ContentBlockAnimatedSummaryListItemIconSelectItems"
                    hide-details="auto"
                >
                    <template v-slot:item="{ item }">
                        <div class="d-flex align-center">
                            <v-icon
                                size="20"
                                color="primary"
                                class="mr-1"
                                >{{ item.icon }}</v-icon
                            >
                            <span>
                                {{ item.text }}
                            </span>
                        </div>
                    </template>
                    <template v-slot:selection="{ item }">
                        <div class="d-flex align-center">
                            <v-icon
                                size="24"
                                color="primary"
                                >{{ item.icon }}</v-icon
                            >
                        </div>
                    </template>
                </v-select>
            </v-col>
            <v-col
                cols="12"
                md="6"
            >
                <v-text-field
                    outlined
                    label="Tekst"
                    :value="
                        lv.text ? getPossibleLogicExpressionOutputs(lv.text).join('/') : '<Brak>'
                    "
                    readonly
                    hide-details="auto"
                    @click="
                        () => {
                            if (lv.text) {
                                expr_dialog_value = lv.text;
                            }
                            expr_dialog = true;
                        }
                    "
                >
                    <div
                        slot="append"
                        :style="
                            lv.text
                                ? { transform: 'translateY(-2px)' }
                                : { transform: 'translateY(2px)' }
                        "
                    >
                        <v-btn
                            icon
                            small
                            color="grey"
                            title="Usuń obraz"
                            @click.stop="lv.text = ''"
                            v-if="lv.text"
                        >
                            <v-icon size="20"> mdi-close </v-icon>
                        </v-btn>
                        <v-tooltip
                            right
                            max-width="350"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    size="18"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-help-circle
                                </v-icon>
                            </template>
                            Wyświetlany tekst możesz zdefiniować przy użyciu wyrażeń logicznych w
                            oparciu o odpowiedzi na inne pytania Quizu.
                        </v-tooltip>
                    </div>
                </v-text-field>
            </v-col>
        </v-row>

        <v-dialog
            v-model="expr_dialog"
            persistent
            max-width="1280"
            scrollable
        >
            <v-card>
                <v-card-title> Edycja wyświetlanego tekstu </v-card-title>
                <v-divider></v-divider>
                <v-card-text
                    class="pt-5"
                    style="max-height: 75vh"
                    v-if="expr_dialog"
                >
                    <ExpressionsWizard
                        v-model="expr_dialog_value"
                        :quiz-boards="quizBoards"
                        :allowedActions="['plaintext']"
                        ref="expr_wizard"
                    />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="grey"
                        text
                        @click="expr_dialog = false"
                    >
                        Anuluj
                    </v-btn>
                    <v-btn
                        color="success"
                        text
                        @click="saveExpr"
                    >
                        Zapisz
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {
    ContentBlockAnimatedSummaryListItemIcon,
    ContentBlockAnimatedSummaryListItemIconSelectItems
} from "../../enums/ContentBlocks";
import { getPossibleLogicExpressionOutputs } from "../../helpers/quiz-logic-expressions";
import ExpressionsWizard from "../ExpressionsWizard/ExpressionsWizard.vue";

const ITEM_TEMPLATE = {
    text: "",
    icon: ContentBlockAnimatedSummaryListItemIcon.CHECKBOX
};

export default {
    components: { ExpressionsWizard },

    props: {
        value: {
            type: Object
        },
        quizBoards: {
            type: Array,
            default() {
                return [];
            }
        }
    },

    data() {
        return {
            lv: ITEM_TEMPLATE,

            expr_dialog: false,
            expr_dialog_value: null,

            ContentBlockAnimatedSummaryListItemIconSelectItems,
            getPossibleLogicExpressionOutputs
        };
    },

    computed: {},

    watch: {
        value: {
            handler() {
                if (this.value) {
                    this.lv = {
                        ...ITEM_TEMPLATE,
                        ...this.value
                    };
                } else {
                    this.lv = {
                        ...ITEM_TEMPLATE
                    };
                }
            },
            immediate: true
        }
    },

    methods: {
        validate() {
            const A = [];

            return A.indexOf(false) === -1;
        },
        getData() {
            const D = JSON.parse(JSON.stringify(this.lv));

            return D;
        },

        saveExpr() {
            if (!this.$refs.expr_wizard.validate()) return;
            this.lv.text = this.expr_dialog_value;
            this.expr_dialog_value = null;
            this.expr_dialog = false;
        }
    }
};
</script>
