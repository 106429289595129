<template>
    <div
        class="cbe-block"
        :class="{
            'cbe-block--drag-disabled': dragDisabled
        }"
    >
        <div
            class="cbe-block__content"
            :class="{
                'cbe-block__content--limited': block.block_type !== ContentBlockType.ROW_2COL
            }"
        >
            <div
                class="cbe-block__imgcontent"
                v-if="block.block_type == ContentBlockType.IMAGE"
            >
                <figure class="cbe-block__image">
                    <v-img
                        :lazy-src="image_lazy_url"
                        :src="image_url"
                    ></v-img>
                    <figcaption v-if="block.image.caption != ''">
                        {{ block.image.caption }}
                    </figcaption>
                </figure>
            </div>
            <div
                class="cbe-block__wysiwygcontent"
                v-else-if="block.block_type == ContentBlockType.WYSIWYG"
                v-html="block.wysiwyg.content"
            ></div>
            <div
                class="cbe-block__aslcontent"
                v-else-if="block.block_type == ContentBlockType.ANIMATED_SUMMARY_LIST"
            >
                <ul>
                    <li
                        v-for="(item, ix) in block.animated_summary_list.items"
                        :key="ix"
                    >
                        <v-icon
                            size="20"
                            color="primary"
                            class="mr-2 flex-shrink-0"
                        >
                            {{ ContentBlockAnimatedSummaryListItemIconName[item.icon] }}
                        </v-icon>
                        <div class="align-self-center flex-grow-1">
                            <div
                                v-for="(e, ix2) in getPossibleLogicExpressionOutputs(item.text)"
                                :key="ix2"
                                :class="{
                                    'mt-n1': ix2 > 0
                                }"
                            >
                                <span
                                    v-if="ix2 > 0"
                                    class="grey--text"
                                >
                                    lub
                                </span>
                                {{ e }}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div
                class="cbe-block__quizboardpredc"
                v-else-if="block.block_type == ContentBlockType.QUIZ_BOARD_PREDEFINED_CONTENT"
            >
                Tu&nbsp;wyświetlona zostanie zawartość Planszy <br />
                (np.&nbsp;zdefiniowane odpowiedzi na&nbsp;zadane pytanie).
            </div>
            <div
                class="cbe-block__chart grey lighten-4"
                :style="`height: ${block.chart.height}px !important`"
                v-else-if="block.block_type == ContentBlockType.CHART"
            >
                <v-icon
                    color="grey lighten-1"
                    size="32"
                    >mdi-chart-line</v-icon
                >
            </div>
            <div
                class="cbe-block__nextbtnplaceholder"
                v-else-if="block.block_type == ContentBlockType.QUIZ_NEXT_BOARD_BUTTON_PLACEHOLDER"
            >
                W&nbsp;tym miejscu zostanie wyświetlony przycisk przejścia do&nbsp;następnej
                planszy.
            </div>
            <div
                class="cbe-block-row2col"
                v-else-if="block.block_type == ContentBlockType.ROW_2COL"
            >
                <div
                    class="cbe-block-row2col__header text-caption text-uppercase grey--text text-center"
                >
                    {{ ContentBlockTypeLabel[block.block_type] }} ({{ block.row_2col.layout }})
                </div>
                <div class="cbe-block-row2col__grid">
                    <div
                        class="cbe-block-row2col__col"
                        :class="{
                            'cbe-block-row2col__col--40':
                                block.row_2col.layout === ContentBlockRow2ColLayout._40_60,
                            'cbe-block-row2col__col--50':
                                block.row_2col.layout === ContentBlockRow2ColLayout._50_50,
                            'cbe-block-row2col__col--60':
                                block.row_2col.layout === ContentBlockRow2ColLayout._60_40
                        }"
                        @click.stop
                        @dblclick.stop
                        @contextmenu.stop
                    >
                        <ContentBlockEditor
                            v-model="block.row_2col.blocks_col1"
                            quiz-context
                            :quiz-boards="quizBoards"
                            :blocks-filter="blocksFilter"
                            :disallowed-block-types="[ContentBlockType.ROW_2COL]"
                            @update="$emit('update')"
                        />
                    </div>
                    <div
                        class="cbe-block-row2col__col"
                        :class="{
                            'cbe-block-row2col__col--40':
                                block.row_2col.layout === ContentBlockRow2ColLayout._60_40,
                            'cbe-block-row2col__col--50':
                                block.row_2col.layout === ContentBlockRow2ColLayout._50_50,
                            'cbe-block-row2col__col--60':
                                block.row_2col.layout === ContentBlockRow2ColLayout._40_60
                        }"
                        @click.stop
                        @dblclick.stop
                        @contextmenu.stop
                    >
                        <ContentBlockEditor
                            v-model="block.row_2col.blocks_col2"
                            quiz-context
                            :quiz-boards="quizBoards"
                            :blocks-filter="blocksFilter"
                            :disallowed-block-types="[ContentBlockType.ROW_2COL]"
                            @update="$emit('update')"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="cbe-block__dragoverlay">
            <v-btn
                icon
                color="secondary"
                title="Przesuń blok"
                class="cbe-block__draghandle"
            >
                <v-icon>mdi-dots-grid</v-icon>
            </v-btn>
        </div>
        <div
            class="cbe-block__visibilityicons"
            v-if="showVisibilityIcons"
            title="Widoczność bloku"
        >
            <v-icon
                size="16"
                v-if="
                    (block.visible_sm === true || block.visible_sm === undefined) &&
                    (block.visible_md === true || block.visible_md === undefined) &&
                    (block.visible_lg === true || block.visible_lg === undefined)
                "
                >mdi-all-inclusive</v-icon
            >
            <template v-else>
                <v-icon
                    size="16"
                    v-if="block.visible_sm === true || block.visible_sm === undefined"
                    >mdi-cellphone</v-icon
                >
                <v-icon
                    size="16"
                    v-if="block.visible_md === true || block.visible_md === undefined"
                    >mdi-tablet</v-icon
                >
                <v-icon
                    size="16"
                    v-if="block.visible_lg === true || block.visible_lg === undefined"
                    >mdi-monitor</v-icon
                >
            </template>
        </div>
    </div>
</template>

<script>
import {
    ContentBlockTypeLabel,
    ContentBlockType,
    ContentBlockAnimatedSummaryListItemIconName,
    ContentBlockRow2ColLayout
} from "../../enums/ContentBlocks";
import ImageOff from "../../assets/img/image-off.svg";
import { getPossibleLogicExpressionOutputs } from "../../helpers/quiz-logic-expressions";

export default {
    name: "ContentBlock",

    props: {
        block: {
            type: Object,
            required: true
        },
        dragDisabled: {
            type: Boolean,
            default: false
        },
        isFirst: {
            type: Boolean,
            default: false
        },
        isLast: {
            type: Boolean,
            default: false
        },
        showVisibilityIcons: {
            type: Boolean,
            default: false
        },
        quizBoards: {
            type: Array,
            default() {
                return [];
            }
        },
        blocksFilter: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {
            getPossibleLogicExpressionOutputs,

            ContentBlockType,
            ContentBlockTypeLabel,
            ContentBlockAnimatedSummaryListItemIconName,
            ContentBlockRow2ColLayout
        };
    },

    computed: {
        image_url() {
            if (this.block && this.block.image) {
                if (["image/gif", "image/svg+xml"].indexOf(this.block.image.mimetype) !== -1) {
                    return this.block.image.url;
                }
                return this.block.image.url_thumb;
            }

            return ImageOff;
        },
        image_lazy_url() {
            if (this.block && this.block.image) {
                return this.block.image.url_placeholder;
            }

            return ImageOff;
        },

        block_is_editable() {
            return (
                this.block &&
                this.block.block_type !== ContentBlockType.QUIZ_BOARD_PREDEFINED_CONTENT
            );
        }
    }
};
</script>
