<template>
    <div
        class="wkmm-fse py-1 rounded-lg pa-2"
        :class="{
            'wkmm-fse--disabled': disabled,
            'grey lighten-4': !is_selected,
            'indigo lighten-5': is_selected
        }"
        @click="
            () => {
                if (disabled) return;
                if (entityType == 'folder') {
                    this.$emit('folder-select', item._id);
                }
            }
        "
        :title="title"
    >
        <div class="wkmm-fse__icon">
            <v-icon
                size="20"
                v-if="entityType == 'folder' && item._id != null"
                >mdi-folder</v-icon
            >
            <v-icon
                size="20"
                v-else-if="entityType == 'folder' && item._id == null"
                >mdi-folder-home</v-icon
            >
            <WkMediaManagerFileIcon
                v-else-if="entityType == 'file'"
                :file-type="item.display_type"
                size="20"
            />
        </div>
        <div class="wkmm-fse__name text-truncate">
            {{ item._id === null ? "Umieść w Folderze głównym" : item.name }}
        </div>
        <div
            class="wkmm-fse__actions"
            v-if="entityType == 'folder' && item._id != null"
        >
            <v-btn
                small
                icon
                color="secondary"
                title="Przejdź do zawartości"
                @click="$emit('open-folder', item._id)"
            >
                <v-icon size="20">mdi-chevron-right</v-icon>
            </v-btn>
        </div>

        <div
            class="wkmm-fse__selovrl"
            :class="{ 'wkmm-fse__selovrl--persistent': is_selected }"
            v-if="entityType == 'folder' && !disabled"
        >
            <div
                class="wkmm-fse__selicon"
                :class="{ 'grey lighten-4': !is_selected, 'indigo lighten-5': is_selected }"
            >
                <v-icon
                    size="20"
                    v-if="is_selected"
                    >mdi-check-circle-outline</v-icon
                >
                <v-icon
                    size="20"
                    v-else
                    >mdi-circle-outline</v-icon
                >
            </div>
        </div>
        <div
            class="wkmm-fse__disovrl"
            v-if="disabled"
        ></div>
    </div>
</template>

<script>
import WkMediaManagerFileIcon from "@/components/WkMediaManager/WkMediaManagerFileIcon";

export default {
    components: {
        WkMediaManagerFileIcon
    },

    props: {
        entityType: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            required: true
        },
        selectedId: {
            type: [String, Number],
            default: ""
        }
    },

    data() {
        return {};
    },

    computed: {
        is_selected() {
            return this.selectedId == this.item._id;
        },
        title() {
            if (!this.disabled) return "";

            if (this.entityType == "file") {
                return "Plik nie może zostać wybrany jako cel przeniesienia pliku lub folderu";
            } else {
                return "Nie można przenieść folderu do niego samego";
            }
        }
    }
};
</script>
