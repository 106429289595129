import moment from "moment-timezone";
import { PaymentIntentCurrency } from "../enums/PaymentIntent";

/*#################
### DATE & TIME ###
################ */
/**
 *
 * @param {number | Date} d Data do sprawdzenia
 * @returns {boolean}
 *
 * Funkcja sprawdza, czy zadana data jest datą dzisiejszą
 */
export function dateIsToday(d) {
    if (typeof d === "number") d = new Date(d);
    const today = new Date();
    return (
        d.getDate() == today.getDate() &&
        d.getMonth() == today.getMonth() &&
        d.getFullYear() == today.getFullYear()
    );
}

/**
 *
 * @param {Date} val Data do sformatowania
 * @returns {string}
 *
 * Funkcja formatuje zadaną datę w sposób zależny od stosunku tej daty do daty aktualnej.
 */
export function formatDateRelativeToCurrentTime(val) {
    const days = ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"];
    const months = {
        0: "stycznia",
        1: "lutego",
        2: "marca",
        3: "kwietnia",
        4: "maja",
        5: "czerwca",
        6: "lipca",
        7: "sierpnia",
        8: "września",
        9: "października",
        10: "listopada",
        11: "grudnia"
    };

    const year = val.getFullYear();
    const date = val.getDate();
    const time = `${
        val.getHours() <= 9 ? val.getHours().toString().padStart(2, "0") : val.getHours()
    }:${val.getMinutes() <= 9 ? val.getMinutes().toString().padStart(2, "0") : val.getMinutes()}`;

    const dayIndex = val.getDay();
    const dayName = days[dayIndex];

    const monthIndex = val.getMonth();
    const monthName = months[monthIndex];

    // Jeżeli data jest datą dzisiejszą, to zwracamy samą godzinę
    if (dateIsToday(val)) {
        return `${time}`;
    }

    // Jeżeli data jest datą z tego samego tygodnia (7 dni do tyłu), to zwracamy dzień tygodnia + godzinę
    if (new Date().getTime() - val.getTime() <= 604800000) {
        return `${dayName}, ${time}`;
    }

    // Jeżeli data jest z tego samego roku co obecny, to zwracamy j.n.
    if (year === new Date().getFullYear()) {
        return `${dayName} ${date} ${monthName} ${time}`;
    }

    return `${date} ${monthName} ${year}, ${time} `;
}

const DAY_SHORT_NAMES = ["Nd", "Pn", "Wt", "Śr", "Cz", "Pt", "Sb"];
const DAY_NAMES = ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"];
const MONTH_SHORT_NAMES = [
    "Sty",
    "Lu",
    "Mar",
    "Kwi",
    "Maj",
    "Cze",
    "Lip",
    "Sie",
    "Wrz",
    "Paź",
    "Lis",
    "Gru"
];
const MONTH_NAMES = [
    "Stycznia",
    "Lutego",
    "Marca",
    "Kwietnia",
    "Maja",
    "Czerwca",
    "Lipca",
    "Sierpnia",
    "Września",
    "Października",
    "Listopada",
    "Grudnia"
];

/**
 * Metoda formatująca podaną datę według przekazanego szablonu
 *
 * Dostępne elementy składowe w szablonie:
 * ##### Dzień:
 * - `d` - dzień miesiąca w postaci dwucyfrowej (z opcjonalnym dopełnieniem zerami)
 * - `D` - skrócona, dwuliterowa nazwa dnia tygodnia (Pn-Nd)
 * - `j` - dzień miesiąca bez dopełnienia zerami
 * - `l (małe 'L')` - pełna nazwa dnia tygodnia
 * - `N` - numer dnia tygodnia (Poniedziałek = 1, Niedziela = 7)
 * - `S` - angielski suffix porządkowy generowany na podstawie dnia miesiąca (`st`, `nd`, `rd`, `th`)
 * - `w` - numeryczna reprezentacja dnia tygodnia (0-based, Niedziela = 0, Sobota = 6)
 * ##### Miesiąc:
 * - `F` - pełna, tekstowa nazwa miesiąca
 * - `m` - numer miesiąca z dopełnieniem zerami (od `01` do `12`)
 * - `M` - skrócona, tekstowa nazwa miesiąca
 * - `n` - numer miesiąca bez dopełniania zerami (od `1` do `12`)
 * ##### Rok
 * - `Y` - numeryczna reprezentacja roku (4 cyfry)
 * - `y` - dwie ostatnie cyfry roku
 * ##### Czas
 * - `a` - Ante meridiem / Post meridiem pisane małymi literami (`am` lub `pm`)
 * - `A` - Ante meridiem / Post meridiem pisane wielkimi literami (`AM` lub `PM`)
 * - `g` - godzina w 12-godzinnym formacie bez dopełnienia zerami (od `1` do `12`)
 * - `G` - godzina w 24-godzinnym formacie bez dopełnienia zerami (od `0` do `23`)
 * - `h` - godzina w 12-godzinnym formacie z dopełnieniem zerami (od `01` do `12`)
 * - `H` - godzina w 24-godzinnym formacie z dopełnieniem zerami (od `00` do `23`)
 * - `i` - minuty z dopełnieniem zerami (od `00` do `59`)
 * - `s` - sekundy z dopełnieniem zerami (od `00` do `59`)
 *
 * @param {number|Date} timestamp - Data wejściowa w formie timestamp
 * @param {string} format - Szablon formatowania daty
 * @returns {string}
 *
 *
 */
export function formatDate(date = Date.now(), format = "d.m.Y") {
    const D = new Date(date);

    const D_DAY = D.getDay();
    const D_DATE = D.getDate();
    const D_MONTH = D.getMonth();
    const D_YEAR = D.getFullYear();
    const D_HOURS = D.getHours();
    const D_MINUTES = D.getMinutes();
    const D_SECONDS = D.getSeconds();

    function englishSuffixResolver(input) {
        if (input === 1) return "st";
        if (input === 2) return "nd";
        if (input === 3) return "rd";

        const MOD_REST = input % 100;
        if (MOD_REST > 20) {
            const MOD_REST2 = MOD_REST % 10;
            if (MOD_REST2 === 1) return "st";
            if (MOD_REST2 === 2) return "nd";
            if (MOD_REST2 === 3) return "rd";
        }

        return "th";
    }

    const AMPM = D_HOURS < 12 ? "am" : "pm";
    const H12 = D_HOURS === 0 ? 12 : D_HOURS > 12 ? D_HOURS - 12 : D_HOURS;

    const REPLACE_DICTIONARY = {
        // Dzień
        d: D_DATE.toString().padStart(2, "0"),
        D: DAY_SHORT_NAMES[D_DAY],
        j: D_DATE.toString(),
        l: DAY_NAMES[D_DAY],
        N: (D_DAY === 0 ? 7 : D_DAY).toString(),
        S: englishSuffixResolver(D_DATE),
        w: D_DAY.toString(),

        // Miesiąc
        F: MONTH_NAMES[D_MONTH],
        m: (D_MONTH + 1).toString().padStart(2, "0"),
        M: MONTH_SHORT_NAMES[D_MONTH],
        n: (D_MONTH + 1).toString(),

        // Rok
        Y: D_YEAR.toString(),
        y: D_YEAR.toString().slice(-2),

        // Czas
        a: AMPM,
        A: AMPM.toUpperCase(),
        g: H12.toString(),
        G: D_HOURS.toString(),
        h: H12.toString().padStart(2, "0"),
        H: D_HOURS.toString().padStart(2, "0"),
        i: D_MINUTES.toString().padStart(2, "0"),
        s: D_SECONDS.toString().padStart(2, "0")
    };

    return format.replace(/[dDjlNSwFmMnYyaAgGhHis]/g, function (match) {
        return REPLACE_DICTIONARY[match];
    });
}

export function displayChatMessageDate(d, opts = {}) {
    const timezone = opts.timezone || "UTC";
    const m = moment(d).tz(timezone);
    const now = moment().tz(timezone);

    // 1.Jeśli data jest w przyszłości, zwróć "Teraz"
    if (now.diff(m, "minutes") < 3 && now.isSame(m, "day")) {
        return "Teraz";
    }

    // 2. Jeśli różnica jest mniejsza niż 24h i to jest ten sam dzień, zwróć tylko czas
    if (now.diff(m, "hours") < 24 && now.isSame(m, "day")) {
        return m.format("HH:mm");
    }

    // 3. Jeśli różnica jest mniejsza niż 24h i to jest wczoraj, zwróć "Wczoraj" + czas
    const YESTERDAY_NOW = now.clone();
    YESTERDAY_NOW.subtract(1, "day");
    if (YESTERDAY_NOW.isSame(m, "day") && now.diff(m, "hours") < 24) {
        return `Wczoraj ${m.format("HH:mm")}`;
    }

    // 4. Jeśli różnica jest mniejsza niż 7 dni, zwróć dzień tygodnia + czas
    if (now.diff(m, "weeks") === 0) {
        return capitalizeString(m.format("dddd, HH:mm"), true);
    }

    // 5. Jeśli różnica jest większa niż 7 dni, zwróć datę + czas
    if (opts.hide_time === true) {
        return m.format("DD.MM.YYYY");
    }

    // 6. W przeciwnym wypadku zwróć pełną datę
    return m.format("DD.MM.YYYY HH:mm");
}

/*#############
### NUMBERS ###
############ */
/**
 * Metoda formatuje przekazaną liczbę według przekazanych parametrów (zwraca ją jako string)
 * @param {number | string} number Liczba do sformatowania
 * @param {number} [decimals=0] Liczba miejsc po przecinku
 * @param {string} [decimal_separator=,] Separator części całkowitej i ułamkowej
 * @param {string} [thousands_separator= ] Separator tysięcy
 * @return {String} Sformatowana liczba
 */
export function formatNumber(
    num,
    decimals = 0,
    decimal_separator = ",",
    thousands_separator = " "
) {
    if (typeof num !== "number" && isNaN(parseFloat(num))) {
        return new Error("Cannot format non-numeric value");
    }

    if (typeof num === "string") num = parseFloat(num);

    let rs = `${num < 0 ? "-" : ""}`;
    const INT_VALUE = Math.abs(Math.trunc(num));

    // 1. Część całkowita liczby
    let intvstr = INT_VALUE.toString();
    const INT_GROUPS = [];
    while (intvstr.length > 0) {
        INT_GROUPS.unshift(intvstr.slice(-3));
        intvstr = intvstr.slice(0, -3);
    }
    rs += INT_GROUPS.join(thousands_separator);

    // 2. Część ułamkowa (opcjonalna)
    if (decimals > 0) {
        let decstr = num.toString().split(".");
        if (decstr.length === 2) {
            decstr = decstr[1];
        } else {
            decstr = "";
        }

        // Musimy zaokrąglić
        if (decstr.length > decimals) {
            let decnum = parseInt(decstr);
            while (Math.trunc(decnum).toString().length > decimals) {
                decnum /= 10;
            }
            decstr = Math.round(decnum).toString();
        }
        // Musimy dopełnić
        else if (decstr.length < decimals) {
            while (decstr.length < decimals) {
                decstr += "0";
            }
        }

        rs += decimal_separator + decstr;
    }

    return rs;
}

/**
 * Metoda służy do formatowania ceny
 * @param {number} price Cena (w groszach)
 * @param {PaymentIntentCurrency} [currency=PaymentIntentCurrency.PLN] Waluta
 * @returns {string} Sformatowana cena
 */
export function formatPrice(price, currency = PaymentIntentCurrency.PLN) {
    const CURRENCY_TEMPLATES = Object.freeze({
        [PaymentIntentCurrency.PLN]: {
            sl: "{price} zł",
            decimals: 2,
            decimal_separator: ",",
            thousands_separator: " "
        }
    });

    return CURRENCY_TEMPLATES[currency].sl.replace(
        "{price}",
        formatNumber(
            price / 100,
            CURRENCY_TEMPLATES[currency].decimals,
            CURRENCY_TEMPLATES[currency].decimal_separator,
            CURRENCY_TEMPLATES[currency].thousands_separator
        )
    );
}

/**
 *
 * @param {number} num Liczba do dopełnienia
 * @param {number} pad Docelowa ilość znaków liczby
 * @returns {string}
 *
 * Dopełnia w razie potrzeby przekazaną liczbę zerami z lewej strony do osiągnięcia długości (ilości znaków) pad.
 */
export function padNumber(num, pad = 2) {
    let str = "";
    for (let i = 1; i <= pad; i++) {
        str += "0";
    }

    return (str + num.toString()).slice(-pad);
}

/*#############
### OBJECTS ###
############ */
export function stripEmptyPropsFromObject(obj, opts = {}) {
    let allow_nulls = false;
    if (opts.allow_nulls === true) allow_nulls = true;

    const lo = JSON.parse(JSON.stringify(obj));

    for (const prop in lo) {
        if (lo[prop] === "" || (lo[prop] === null && !allow_nulls)) {
            delete lo[prop];
        } else if (typeof lo[prop] === "object") {
            lo[prop] = stripEmptyPropsFromObject(lo[prop], opts);
        }
    }

    return lo;
}

/*###########
### FILES ###
########## */
/**
 *
 * @param {Integer} bytes Rozmiar pliku w bajtach
 * @return {String} Sformatowany rozmiar
 */
export function formatFileSize(bytes) {
    if (bytes == undefined) return "";
    if (typeof bytes !== "number") bytes = parseInt(bytes);
    if (isNaN(bytes)) return "";

    if (bytes < 1024) {
        return bytes + " B";
    }
    if (bytes < 1024 * 1024) {
        return (bytes / 1024).toFixed(2).replace(".", ",") + " KB";
    }
    if (bytes < 1024 * 1024 * 1024) {
        return (bytes / (1024 * 1024)).toFixed(2).replace(".", ",") + " MB";
    }
    if (bytes < 1024 * 1024 * 1024 * 1024) {
        return (bytes / (1024 * 1024 * 1024)).toFixed(2).replace(".", ",") + " GB";
    }
    return bytes;
}

/*#############
### STRINGS ###
#############*/
export function capitalizeString(str, only_first_word = false) {
    const SPL = str.split(" ");
    if (SPL.length < 1) return "";

    if (only_first_word === true) {
        if (SPL[0].length > 0) SPL[0] = SPL[0].slice(0, 1).toUpperCase() + SPL[0].slice(1);
        return SPL.join(" ");
    }

    return SPL.map(e => (e.length > 0 ? e.slice(0, 1).toUpperCase() + e.slice(1) : "")).join(" ");
}
