export default {
    namespaced: true,

    state: () => ({
        // 1. APPOINTMENTS
        appointments: [],
        appointments_fetch_map: new Map(),

        // 2. USERS
        users: [],
        users_fetch_map: new Map(),

        // 3. ARP
        arp: [],
        arp_fetch_map: new Map(),

        // 4. PRODUCTS
        products: [],
        products_fetch_map: new Map(),

        // 5. USER SEGMENTS
        user_segments: [],
        user_segments_fetch_map: new Map(),

        // 6. SERVICES
        services: [],
        services_fetch_map: new Map()
    }),

    getters: {
        // 1. APPOINTMENTS
        retrieveAppointment: state => id => {
            return state.appointments.find(it => it._id === id);
        },

        // 2. USERS
        retrieveUser: state => id => {
            return state.users.find(it => it._id === id);
        },

        // 3. ARP
        retrieveAppointmentRescheduleProposal: state => id => {
            return state.arp.find(it => it._id === id);
        },

        // 4. PRODUCTS
        retrieveProduct: state => id => {
            return state.products.find(it => it._id === id);
        },

        // 5. USER SEGMENTS
        retrieveUserSegment: state => id => {
            return state.user_segments.find(it => it._id === id);
        },

        // 6. SERVICES
        retrieveService: state => id => {
            return state.services.find(it => it._id === id);
        }
    },

    mutations: {
        // 1. APPOINTMENTS
        updateOrInsertAppointment(state, appointment) {
            const ix = state.appointments.findIndex(it => it._id === appointment._id);
            if (ix === -1) {
                state.appointments.push(appointment);
            } else {
                state.appointments.splice(ix, 1, {
                    ...state.appointments[ix],
                    ...appointment
                });
            }
        },

        // 2. USERS
        updateOrInsertUser(state, user) {
            const ix = state.users.findIndex(it => it._id === user._id);
            if (ix === -1) {
                state.users.push(user);
            } else {
                state.users.splice(ix, 1, {
                    ...state.users[ix],
                    ...user
                });
            }
        },

        // 3. ARP
        updateOrInsertAppointmentRescheduleProposal(state, arp) {
            const ix = state.arp.findIndex(it => it._id === arp._id);
            if (ix === -1) {
                state.arp.push(arp);
            } else {
                state.arp.splice(ix, 1, {
                    ...state.arp[ix],
                    ...arp
                });
            }
        },

        // 4. PRODUCTS
        updateOrInsertProduct(state, product) {
            const ix = state.products.findIndex(it => it._id === product._id);
            if (ix === -1) {
                state.products.push(product);
            } else {
                state.products.splice(ix, 1, {
                    ...state.products[ix],
                    ...product
                });
            }
        },

        // 5. USER SEGMENTS
        updateOrInsertUserSegment(state, user_segment) {
            const ix = state.user_segments.findIndex(it => it._id === user_segment._id);
            if (ix === -1) {
                state.user_segments.push(user_segment);
            } else {
                state.user_segments.splice(ix, 1, {
                    ...state.user_segments[ix],
                    ...user_segment
                });
            }
        },

        // 6. SERVICES
        updateOrInsertService(state, service) {
            const ix = state.services.findIndex(it => it._id === service._id);
            if (ix === -1) {
                state.services.push(service);
            } else {
                state.services.splice(ix, 1, {
                    ...state.services[ix],
                    ...service
                });
            }
        },

        // 7. GENERAL
        nuke(state) {
            state.appointments = [];
            state.appointments_fetch_map = new Map();

            state.users = [];
            state.users_fetch_map = new Map();

            state.arp = [];
            state.arp_fetch_map = new Map();

            state.products = [];
            state.products_fetch_map = new Map();

            state.user_segments = [];
            state.user_segments_fetch_map = new Map();

            state.services = [];
            state.services_fetch_map = new Map();
        }
    },

    actions: {
        // 1. APPOINTMENTS
        async getAppointment({ state }, appointment_id) {
            // 1. Sprawdzamy, czy mamy już taki element
            const APPOINTMENT = state.appointments.find(it => it._id === appointment_id);
            if (APPOINTMENT) return APPOINTMENT;

            // 2. Sprawdzamy, czy czasem już go nie pobieramy
            const PROMISE = state.appointments_fetch_map.get(appointment_id);
            if (PROMISE) {
                return await PROMISE;
            }

            // 3. Nie mamy go pobranego ani nie pobieramy - trzeba więc go pobrać
            state.appointments_fetch_map.set(
                appointment_id,
                new Promise(async (resolve, reject) => {
                    try {
                        const R = await this._vm.$axios.$get(`/appointments/${appointment_id}`, {
                            supress_errors: true
                        });
                        state.appointments.push(R.appointment);
                        return resolve(R.appointment);
                    } catch (err) {
                        return reject(err);
                    }
                })
            );
            const O = await state.appointments_fetch_map.get(appointment_id);
            if (O === undefined) {
                throw new Error("Could not fetch Appointment!");
            }

            state.appointments_fetch_map.delete(appointment_id);
            return O;
        },

        // 2. USERS
        async getUser({ state }, user_id) {
            // 1. Sprawdzamy, czy mamy już taki element
            const USER = state.users.find(it => it._id === user_id);
            if (USER) return USER;

            // 2. Sprawdzamy, czy czasem już go nie pobieramy
            const PROMISE = state.users_fetch_map.get(user_id);
            if (PROMISE) {
                return await PROMISE;
            }

            // 3. Nie mamy go pobranego ani nie pobieramy - trzeba więc go pobrać
            state.users_fetch_map.set(
                user_id,
                new Promise(async (resolve, reject) => {
                    try {
                        const R = await this._vm.$axios.$get(`/users/${user_id}`, {
                            supress_errors: true
                        });
                        state.users.push(R.user);
                        return resolve(R.user);
                    } catch (err) {
                        return reject(err);
                    }
                })
            );
            const O = await state.users_fetch_map.get(user_id);
            if (O === undefined) {
                throw new Error("Could not fetch User!");
            }

            state.users_fetch_map.delete(user_id);
            return O;
        },

        // 3. ARP
        async getAppointmentRescheduleProposal({ state }, arp_id) {
            // 1. Sprawdzamy, czy mamy już taki element
            const ARP = state.arp.find(it => it._id === arp_id);
            if (ARP) return ARP;

            // 2. Sprawdzamy, czy czasem już go nie pobieramy
            const PROMISE = state.arp_fetch_map.get(arp_id);
            if (PROMISE) {
                return await PROMISE;
            }

            // 3. Nie mamy go pobranego ani nie pobieramy - trzeba więc go pobrać
            state.arp_fetch_map.set(
                arp_id,
                new Promise(async (resolve, reject) => {
                    try {
                        const R = await this._vm.$axios.$get(
                            `/appointment-reschedule-proposals/${arp_id}`,
                            {
                                supress_errors: true
                            }
                        );
                        state.arp.push(R.appointment_reschedule_proposal);
                        return resolve(R.appointment_reschedule_proposal);
                    } catch (err) {
                        return reject(err);
                    }
                })
            );
            const O = await state.arp_fetch_map.get(arp_id);
            if (O === undefined) {
                throw new Error("Could not fetch ARP!");
            }

            state.arp_fetch_map.delete(arp_id);
            return O;
        },

        // 4. PRODUCTS
        async getProduct({ state }, product_id) {
            // 1. Sprawdzamy, czy mamy już taki element
            const PRODUCT = state.products.find(it => it._id === product_id);
            if (PRODUCT) return PRODUCT;

            // 2. Sprawdzamy, czy czasem już go nie pobieramy
            const PROMISE = state.products_fetch_map.get(product_id);
            if (PROMISE) {
                return await PROMISE;
            }

            // 3. Nie mamy go pobranego ani nie pobieramy - trzeba więc go pobrać
            state.products_fetch_map.set(
                product_id,
                new Promise(async (resolve, reject) => {
                    try {
                        const R = await this._vm.$axios.$get(`/products/${product_id}`, {
                            supress_errors: true
                        });
                        state.products.push(R.product);
                        return resolve(R.product);
                    } catch (err) {
                        return reject(err);
                    }
                })
            );
            const O = await state.products_fetch_map.get(product_id);
            if (O === undefined) {
                throw new Error("Could not fetch Product!");
            }

            state.products_fetch_map.delete(product_id);
            return O;
        },

        // 5. USER SEGMENTS
        async getUserSegment({ state }, user_segment_id) {
            // 1. Sprawdź, czy już mamy segment użytkownika
            const USER_SEGMENT = state.user_segments.find(it => it._id === user_segment_id);
            if (USER_SEGMENT) return USER_SEGMENT;

            // 2. Sprawdź, czy segment użytkownika jest już w trakcie pobierania
            const PROMISE = state.user_segments_fetch_map.get(user_segment_id);
            if (PROMISE) {
                return await PROMISE;
            }

            // 3. Rozpocznij pobieranie segmentu użytkownika
            state.user_segments_fetch_map.set(
                user_segment_id,
                new Promise(async (resolve, reject) => {
                    try {
                        const R = await this._vm.$axios.$get(`/user-segments/${user_segment_id}`, {
                            supress_errors: true
                        });
                        state.user_segments.push(R.user_segment);
                        return resolve(R.user_segment);
                    } catch (err) {
                        return reject(err);
                    }
                })
            );
            const O = await state.user_segments_fetch_map.get(user_segment_id);
            if (O === undefined) {
                throw new Error("Could not fetch User Segment!");
            }

            // 4. Usuń zapytanie z mapy
            state.user_segments_fetch_map.delete(user_segment_id);
            return O;
        },

        // 6. SERVICES
        async getService({ state }, service_id) {
            // 1. Sprawdzamy, czy mamy już taką usługę
            const SERVICE = state.services.find(it => it._id === service_id);
            if (SERVICE) return SERVICE;

            // 2. Sprawdzamy, czy usługa jest już w trakcie pobierania
            const PROMISE = state.services_fetch_map.get(service_id);
            if (PROMISE) {
                return await PROMISE;
            }

            // 3. Jeśli nie, zaczynamy pobieranie
            state.services_fetch_map.set(
                service_id,
                new Promise(async (resolve, reject) => {
                    try {
                        const R = await this._vm.$axios.$get(`/services/${service_id}`, {
                            supress_errors: true
                        });
                        state.services.push(R.service);
                        return resolve(R.service);
                    } catch (err) {
                        return reject(err);
                    }
                })
            );

            const O = await state.services_fetch_map.get(service_id);
            if (O === undefined) {
                throw new Error("Could not fetch Service!");
            }

            // Usunięcie z mapy fetchowania po zakończeniu
            state.services_fetch_map.delete(service_id);
            return O;
        }
    }
};
