"use strict";

export const IoEmit = Object.freeze({
    JOIN_ROOM: "join_room",
    LEAVE_ROOM: "leave_room"
});
export const IoPredefinedRooms = Object.freeze({
    CALENDAR: "calendar"
});

export const IoEvent = Object.freeze({
    MEDIAMANAGER_FOLDER_CREATED: "MediamanagerFolder.created",
    MEDIAMANAGER_FOLDER_UPDATED: "MediamanagerFolder.updated",
    MEDIAMANAGER_FOLDER_DELETED: "MediamanagerFolder.deleted",

    MEDIAMANAGER_FILE_CREATED: "MediamanagerFile.created",
    MEDIAMANAGER_FILE_UPDATED: "MediamanagerFile.updated",
    MEDIAMANAGER_FILE_DELETED: "MediamanagerFile.deleted",

    QUIZ_BOARD_CREATED: "QuizBoard.created",
    QUIZ_BOARD_UPDATED: "QuizBoard.updated",
    QUIZ_BOARD_DELETED: "QuizBoard.deleted",

    QUIZ_CREATED: "Quiz.created",
    QUIZ_UPDATED: "Quiz.updated",
    QUIZ_DELETED: "Quiz.deleted",

    WORDPRESS_BLOG_POSTS_SYNC_PERFORMED: "WordpressBlogPost.sync_performed",

    CALENDAR_SLOTS_BOOKED: "Calendar.slots_booked",
    CALENDAR_SLOTS_RELEASED: "Calendar.slots_released",

    ADMIN_NOTIFICATION_CREATED: "AdminNotification.created",
    ADMIN_NOTIFICATION_UPDATED: "AdminNotification.updated",

    HELPDESK_MESSAGE_CREATED: "HelpdeskMessage.created",
    HELPDESK_MESSAGE_READ: "HelpdeskMessage.read",

    HELPDESK_THREAD_CREATED: "HelpdeskThread.created",
    HELPDESK_THREAD_UPDATED: "HelpdeskThread.updated",

    CHAT_UPDATED: "Chat.updated",

    CHAT_MESSAGE_CREATED: "ChatMessage.created",
    CHAT_MESSAGE_DELETED: "ChatMessage.deleted"
});
