import { io } from "socket.io-client";
import store from "../store";

// import { playNewNotificationSound } from "../helpers/sounds";

export default {
    install(Vue) {
        Vue.prototype.$io = io(store.state.API_BASE_URL, {
            transports: ["websocket"],
            autoConnect: false
        });

        Vue.prototype.$io.io.on("reconnect", async () => {
            console.log(`[Socket.io] Reconnected`);
            if (store.getters["auth/isLoggedIn"]) {
                await store.dispatch("auth/socketJoinRooms");
            }
        });
        Vue.prototype.$io.on("disconnect", async reason => {
            store.state.auth.socket_room_joined = false;
            console.log(`[Socket.io] Disconnected (${reason})`);
        });
    }
};
