// LANG
export const UserLang = Object.freeze({
    PL: "pl"
});
export const UserLangLabel = Object.freeze({
    [UserLang.PL]: "polski"
});
export const UserLangSelectItems = Object.values(UserLang).map(it => {
    return {
        text: UserLangLabel[it],
        value: it
    };
});

// ROLE
export const UserRole = Object.freeze({
    ADMIN: "admin",
    THERAPIST: "therapist",
    USER: "user"
});
export const UserRoleLabel = Object.freeze({
    [UserRole.ADMIN]: "Administrator",
    [UserRole.THERAPIST]: "Terapeuta",
    [UserRole.USER]: "Klient"
});
export const UserRoleLabelColor = Object.freeze({
    [UserRole.ADMIN]: "error",
    [UserRole.THERAPIST]: "primary",
    [UserRole.USER]: "secondary"
});
export const UserRoleSelectItems = Object.values(UserRole).map(it => {
    return {
        text: UserRoleLabel[it],
        value: it
    };
});

// STATUS
export const UserStatus = Object.freeze({
    ACTIVE: "active",
    SUSPENDED: "suspended"
});
export const UserStatusLabel = Object.freeze({
    [UserStatus.ACTIVE]: "Aktywne",
    [UserStatus.SUSPENDED]: "Zawieszone"
});
export const UserStatusLabelColor = Object.freeze({
    [UserStatus.ACTIVE]: "primary",
    [UserStatus.SUSPENDED]: "secondary"
});
export const UserStatusSelectItems = Object.values(UserStatus).map(it => {
    return {
        text: UserStatusLabel[it],
        value: it
    };
});

// SIGNUP SOURCE
export const UserSignupSource = Object.freeze({
    RISIFY_SELF_SIGNUP: "ris_ss",
    RISIFY_MANUALLY_CREATED: "ris_mc",
    VCITA: "vcita"
});
export const UserSignupSourceLabel = Object.freeze({
    [UserSignupSource.RISIFY_SELF_SIGNUP]: "Samodzielna rejestracja",
    [UserSignupSource.RISIFY_MANUALLY_CREATED]: "Utworzone ręcznie",
    [UserSignupSource.VCITA]: "VCITA (migracja)"
});
export const UserSignupSourceSelectItems = Object.values(UserSignupSource).map(it => {
    return {
        text: UserSignupSourceLabel[it],
        value: it
    };
});

// GENDER
export const UserGender = Object.freeze({
    MALE: "male",
    FEMALE: "female",
    NONBINARY: "nonbinary",
    UNKNOWN: "unknown"
});
export const UserGenderLabel = Object.freeze({
    [UserGender.MALE]: "Mężczyzna",
    [UserGender.FEMALE]: "Kobieta",
    [UserGender.NONBINARY]: "Niebinarna",
    [UserGender.UNKNOWN]: "Nie podano"
});
export const UserGenderSelectItems = Object.values(UserGender).map(it => {
    return {
        text: UserGenderLabel[it],
        value: it
    };
});

// AUTH METHOD
export const UserAuthMethod = Object.freeze({
    OTP: "otp",
    PASSWORD: "password"
});
export const UserAuthMethodLabel = Object.freeze({
    [UserAuthMethod.OTP]: "Jednorazowy kod",
    [UserAuthMethod.PASSWORD]: "Hasło"
});

// THERAPIST SPECIALIZATION
export const UserTherapistSpecialization = Object.freeze({
    ADDICTION: "addiction",
    DEPRESSION: "depression",
    ANXIETY: "anxiety",
    PROCRASTINATION: "procrastination",
    STRESS: "stress",
    RELATIONSHIP: "relationship",
    TRAUMA: "trauma",
    ANGER: "anger",
    SADNESS: "sadness",
    SELF_CONFIDENCE: "self_confidence",
    OTHER: "other"
});
export const UserTherapistSpecializationLabel = Object.freeze({
    [UserTherapistSpecialization.ADDICTION]: "Uzależnienie",
    [UserTherapistSpecialization.DEPRESSION]: "Depresja",
    [UserTherapistSpecialization.ANXIETY]: "Lęk",
    [UserTherapistSpecialization.PROCRASTINATION]: "Prokrastynacja",
    [UserTherapistSpecialization.STRESS]: "Stres",
    [UserTherapistSpecialization.RELATIONSHIP]: "Związki",
    [UserTherapistSpecialization.TRAUMA]: "Trauma",
    [UserTherapistSpecialization.ANGER]: "Agresja",
    [UserTherapistSpecialization.SADNESS]: "Smutek",
    [UserTherapistSpecialization.SELF_CONFIDENCE]: "Samoocena",
    [UserTherapistSpecialization.OTHER]: "Inne"
});
export const UserTherapistSpecializationSelectItems = Object.values(
    UserTherapistSpecialization
).map(it => {
    return {
        text: UserTherapistSpecializationLabel[it],
        value: it
    };
});

// CLIENT AGE GROUP
export const UserClientAgeGroup = Object.freeze({
    UNKNOWN: "unknown",
    UNDER_18: "u18",
    FROM_18_TO_30: "18_30",
    FROM_31_TO_40: "31_40",
    FROM_41_TO_50: "41_50",
    OVER_50: "o50"
});
export const UserClientAgeGroupLabel = Object.freeze({
    [UserClientAgeGroup.UNKNOWN]: "Nieznana",
    [UserClientAgeGroup.UNDER_18]: "Poniżej 18 lat",
    [UserClientAgeGroup.FROM_18_TO_30]: "18-30 lat",
    [UserClientAgeGroup.FROM_31_TO_40]: "31-40 lat",
    [UserClientAgeGroup.FROM_41_TO_50]: "41-50 lat",
    [UserClientAgeGroup.OVER_50]: "Powyżej 50 lat"
});

// CLIENT FIRST THERAPY
export const UserClientFirstTherapy = Object.freeze({
    UNKNOWN: "unknown",
    YES: "yes",
    NO: "no"
});
export const UserClientFirstTherapyLabel = Object.freeze({
    [UserClientFirstTherapy.UNKNOWN]: "Brak danych",
    [UserClientFirstTherapy.YES]: "Tak",
    [UserClientFirstTherapy.NO]: "Nie"
});

// THERAPY TYPE
export const UserTherapistTherapyType = Object.freeze({
    ADULTS_INDIVIDUAL: "adults_individual",
    ADULTS_COUPLES: "adults_couples",
    KIDS_AND_TEENS: "kids_and_teens",
    FAMILY: "family",
    EMDR: "emdr"
});
export const UserTherapistTherapyTypeLabel = Object.freeze({
    [UserTherapistTherapyType.ADULTS_INDIVIDUAL]: "Indywidualna",
    [UserTherapistTherapyType.ADULTS_COUPLES]: "Dla par",
    [UserTherapistTherapyType.KIDS_AND_TEENS]: "Dla dzieci i młodzieży",
    [UserTherapistTherapyType.FAMILY]: "Dla rodzin",
    [UserTherapistTherapyType.EMDR]: "EMDR"
});

export const UserPrimaryTherapyType = Object.freeze({
    UNKNOWN: "unknown",
    ...UserTherapistTherapyType
});
export const UserPrimaryTherapyTypeLabel = Object.freeze({
    [UserPrimaryTherapyType.UNKNOWN]: "Brak danych",
    ...UserTherapistTherapyTypeLabel
});
export const UserTherapistTherapyTypeSelectItems = Object.values(UserTherapistTherapyType).map(
    it => {
        return {
            text: UserTherapistTherapyTypeLabel[it],
            value: it
        };
    }
);

// THERAPY DIRECTION
export const UserTherapistTherapyDirection = Object.freeze({
    CBT: "cbt",
    PSYCHODYNAMIC: "psychodynamic",
    EMDR: "emdr",
    INTEGRATIVE: "integrative",
    GESTALT: "gestalt",
    HUMANISTIC: "humanistic",
    TSNR: "tsnr",
    SYSTEMIC: "systemic",
    EXISTENTIAL: "existential",
    PSYCHOANALYSIS: "psychoanalysis",
    OTHER: "other"
});
export const UserTherapistTherapyDirectionLabel = Object.freeze({
    [UserTherapistTherapyDirection.CBT]: "Poznawczo-behawioralna",
    [UserTherapistTherapyDirection.PSYCHODYNAMIC]: "Psychodynamiczna",
    [UserTherapistTherapyDirection.EMDR]: "EMDR",
    [UserTherapistTherapyDirection.INTEGRATIVE]: "Integracyjna",
    [UserTherapistTherapyDirection.GESTALT]: "Gestalt",
    [UserTherapistTherapyDirection.HUMANISTIC]: "Humanistyczna",
    [UserTherapistTherapyDirection.TSNR]: "Skoncentrowana na rozwiązaniach",
    [UserTherapistTherapyDirection.SYSTEMIC]: "Systematyczna",
    [UserTherapistTherapyDirection.EXISTENTIAL]: "Egzystencjalna",
    [UserTherapistTherapyDirection.PSYCHOANALYSIS]: "Psychoanalityczna",
    [UserTherapistTherapyDirection.OTHER]: "Inne"
});
export const UserTherapistTherapyDirectionSelectItems = Object.values(
    UserTherapistTherapyDirection
).map(it => {
    return {
        text: UserTherapistTherapyDirectionLabel[it],
        value: it
    };
});

// PREFERRED THERAPY HOUR
const UserPreferredTherapyHour = Object.freeze({
    IRRELEVANT: "irrelevant",
    H8_TO_H11: "8:00-11:00",
    H11_TO_H14: "11:00-14:00",
    H14_TO_H16: "14:00-16:00",
    H14_TO_H17: "14:00-17:00",
    H16_TO_H18: "16:00-18:00",
    H17_TO_H20: "17:00-20:00",
    H18_TO_H20: "18:00-20:00",
    H20_TO_H22: "20:00-22:00"
});
export const UserPreferredTherapyHourLabel = Object.freeze({
    [UserPreferredTherapyHour.IRRELEVANT]: "Dowolne",
    [UserPreferredTherapyHour.H8_TO_H11]: "8:00 - 11:00",
    [UserPreferredTherapyHour.H11_TO_H14]: "11:00 - 14:00",
    [UserPreferredTherapyHour.H14_TO_H16]: "14:00 - 16:00",
    [UserPreferredTherapyHour.H14_TO_H17]: "14:00 - 17:00",
    [UserPreferredTherapyHour.H16_TO_H18]: "16:00 - 18:00",
    [UserPreferredTherapyHour.H17_TO_H20]: "17:00 - 20:00",
    [UserPreferredTherapyHour.H18_TO_H20]: "18:00 - 20:00",
    [UserPreferredTherapyHour.H20_TO_H22]: "20:00 - 22:00"
});

// PREFERRED THERAPY START
export const UserPreferredTherapyStart = Object.freeze({
    IRRELEVANT: "irrelevant",
    WITHIN_48H: "48h",
    WITHIN_7D: "7d"
});
export const UserPreferredTherapyStartLabel = Object.freeze({
    [UserPreferredTherapyStart.IRRELEVANT]: "Dowolna",
    [UserPreferredTherapyStart.WITHIN_48H]: "W ciągu 48 godzin",
    [UserPreferredTherapyStart.WITHIN_7D]: "W ciągu 7 dni"
});

// THERAPIST SETTLEMENT CURRENCY
export const UserTherapistSettlementCurrency = Object.freeze({
    PLN: "pln"
});
export const UserTherapistSettlementCurrencyLabel = Object.freeze({
    [UserTherapistSettlementCurrency.PLN]: "Polski Złoty (PLN)"
});
export const UserTherapistSettlementCurrencyLabelShort = Object.freeze({
    [UserTherapistSettlementCurrency.PLN]: "zł"
});
export const UserTherapistSettlementCurrencySelectItems = Object.values(
    UserTherapistSettlementCurrency
).map(it => {
    return {
        text: UserTherapistSettlementCurrencyLabel[it],
        value: it
    };
});

// THERAPIST SETTLEMENT STRATEGY
export const UserTherapistSettlementStrategy = Object.freeze({
    MONTHLY: "monthly"
});
export const UserTherapistSettlementStrategyLabel = Object.freeze({
    [UserTherapistSettlementStrategy.MONTHLY]: "Miesięcznie"
});
export const UserTherapistSettlementStrategySelectItems = Object.values(
    UserTherapistSettlementStrategy
).map(it => {
    return {
        text: UserTherapistSettlementStrategyLabel[it],
        value: it
    };
});

// THERAPIST SETTLEMENT PARTY
export const UserTherapistSettlementParty = Object.freeze({
    RISIFY: "risify"
});
export const UserTherapistSettlementPartyLabel = Object.freeze({
    [UserTherapistSettlementParty.RISIFY]: "Risify"
});
export const UserTherapistSettlementPartySelectItems = Object.values(
    UserTherapistSettlementParty
).map(it => {
    return {
        text: UserTherapistSettlementPartyLabel[it],
        value: it
    };
});

// THERAPIST THS CONTRACT NBP ACTION
export const UserTherapistThsContractNextBillingPeriodUpdateAction = Object.freeze({
    NONE: "none",
    UPDATE: "update",
    DELETE: "delete"
});
export const UserTherapistThsContractNextBillingPeriodUpdateActionLabel = Object.freeze({
    [UserTherapistThsContractNextBillingPeriodUpdateAction.NONE]: "Brak",
    [UserTherapistThsContractNextBillingPeriodUpdateAction.UPDATE]: "Zmiana umowy",
    [UserTherapistThsContractNextBillingPeriodUpdateAction.DELETE]: "Wygaszenie umowy"
});
export const UserTherapistThsContractNextBillingPeriodUpdateActionColor = Object.freeze({
    [UserTherapistThsContractNextBillingPeriodUpdateAction.NONE]: "grey lighten-3",
    [UserTherapistThsContractNextBillingPeriodUpdateAction.UPDATE]: "secondary",
    [UserTherapistThsContractNextBillingPeriodUpdateAction.DELETE]: "error"
});
export const UserTherapistThsContractNextBillingPeriodUpdateActionSelectItems = Object.values(
    UserTherapistThsContractNextBillingPeriodUpdateAction
).map(it => {
    return {
        text: UserTherapistThsContractNextBillingPeriodUpdateActionLabel[it],
        value: it
    };
});

// THERAPIST AVAILABILITY WEEKDAY
export const UserTherapistAvailabilityWeekday = Object.freeze({
    MONDAY: "MONDAY",
    TUESDAY: "TUESDAY",
    WEDNESDAY: "WEDNESDAY",
    THURSDAY: "THURSDAY",
    FRIDAY: "FRIDAY",
    SATURDAY: "SATURDAY",
    SUNDAY: "SUNDAY"
});
export const UserTherapistAvailabilityWeekdayLabel = Object.freeze({
    [UserTherapistAvailabilityWeekday.MONDAY]: "PN",
    [UserTherapistAvailabilityWeekday.TUESDAY]: "WT",
    [UserTherapistAvailabilityWeekday.WEDNESDAY]: "ŚR",
    [UserTherapistAvailabilityWeekday.THURSDAY]: "CZ",
    [UserTherapistAvailabilityWeekday.FRIDAY]: "PT",
    [UserTherapistAvailabilityWeekday.SATURDAY]: "SB",
    [UserTherapistAvailabilityWeekday.SUNDAY]: "ND"
});
export const UserTherapistAvailabilityWeekdayLabelFull = Object.freeze({
    [UserTherapistAvailabilityWeekday.MONDAY]: "Poniedziałek",
    [UserTherapistAvailabilityWeekday.TUESDAY]: "Wtorek",
    [UserTherapistAvailabilityWeekday.WEDNESDAY]: "Środa",
    [UserTherapistAvailabilityWeekday.THURSDAY]: "Czwartek",
    [UserTherapistAvailabilityWeekday.FRIDAY]: "Piątek",
    [UserTherapistAvailabilityWeekday.SATURDAY]: "Sobota",
    [UserTherapistAvailabilityWeekday.SUNDAY]: "Niedziela"
});
export const UserTherapistAvailabilityWeekdaySelectItems = Object.values(
    UserTherapistAvailabilityWeekday
).map(it => {
    return {
        text: UserTherapistAvailabilityWeekdayLabelFull[it],
        value: it
    };
});

// USER ABSENCE
export const UserAbsenceType = Object.freeze({
    SICKNESS: "sickness",
    HOLIDAY: "holiday",
    OTHER: "other"
});
export const UserAbsenceTypeLabel = Object.freeze({
    [UserAbsenceType.SICKNESS]: "Choroba",
    [UserAbsenceType.HOLIDAY]: "Urlop",
    [UserAbsenceType.OTHER]: "Inny"
});
export const UserAbsenceTypeColor = Object.freeze({
    [UserAbsenceType.SICKNESS]: "error",
    [UserAbsenceType.HOLIDAY]: "success",
    [UserAbsenceType.OTHER]: "secondary"
});
export const UserAbsenceTypeSelectItems = Object.values(UserAbsenceType).map(it => {
    return {
        text: UserAbsenceTypeLabel[it],
        value: it
    };
});

// THERAPIST ASSIGNED PRODUCT COMMISSION STRATEGY
export const UserTherapistAssignedProductCommissionStrategy = Object.freeze({
    PERCENTAGE: "percentage",
    FIXED_AMOUNT: "fixed_amount"
});
export const UserTherapistAssignedProductCommissionStrategyLabel = Object.freeze({
    [UserTherapistAssignedProductCommissionStrategy.PERCENTAGE]: "Prowizja procentowa",
    [UserTherapistAssignedProductCommissionStrategy.FIXED_AMOUNT]: "Prowizja stała"
});
export const UserTherapistAssignedProductCommissionStrategySelectItems = Object.values(
    UserTherapistAssignedProductCommissionStrategy
).map(it => {
    return {
        text: UserTherapistAssignedProductCommissionStrategyLabel[it],
        value: it
    };
});

// TA PRODUCT COMMISSION PROGRESSIVE VALUE STRATEGY
export const UserTherapistAssignedProductCommissionProgressiveValueStrategy = Object.freeze({
    NONE: "none",
    RISIFY_PVS_1: "ris_pvs_1"
});
export const UserTherapistAssignedProductCommissionProgressiveValueStrategyLabel = Object.freeze({
    [UserTherapistAssignedProductCommissionProgressiveValueStrategy.NONE]: "Brak",
    [UserTherapistAssignedProductCommissionProgressiveValueStrategy.RISIFY_PVS_1]: "Risify PVS1"
});
export const UserTherapistAssignedProductCommissionProgressiveValueStrategySelectItems =
    Object.values(UserTherapistAssignedProductCommissionProgressiveValueStrategy).map(it => {
        return {
            text: UserTherapistAssignedProductCommissionProgressiveValueStrategyLabel[it],
            value: it
        };
    });

// NOTIFICATION CHANNELS
export const UserNotificationChannel = Object.freeze({
    GENERAL: "general",
    THERAPY_SESSIONS: "ths",
    CHAT: "chat",
    OTHER: "other"
});
export const UserNotificationChannelLabel = Object.freeze({
    [UserNotificationChannel.GENERAL]: "Ogólne",
    [UserNotificationChannel.THERAPY_SESSIONS]: "Sesje terapeutyczne",
    [UserNotificationChannel.CHAT]: "Czat",
    [UserNotificationChannel.OTHER]: "Inne"
});

// MINDGRAM
export const UserMindgramCategory = Object.freeze({
    PSYCHOTHERAPIST: "psychotherapist",
    COACH: "coach"
});
export const UserMindgramCategoryLabel = Object.freeze({
    [UserMindgramCategory.PSYCHOTHERAPIST]: "Psychoterapeuta",
    [UserMindgramCategory.COACH]: "Coach"
});
export const UserMindgramCategorySelectItems = Object.values(UserMindgramCategory).map(it => ({
    text: UserMindgramCategoryLabel[it],
    value: it
}));

// PERMISSIONS
export const UserPermissionGroup = Object.freeze({
    ROOT: "root",
    USERS_READ: "users.read",
    USERS_WRITE: "users.write",
    MINDGRAM_INTEGRATION_READ: "mindgram_integration.read",
    MINDGRAM_INTEGRATION_WRITE: "mindgram_integration.write",
    USER_SEGMENTS_READ: "user_segments.read",
    USER_SEGMENTS_WRITE: "user_segments.write",
    AFFILIATE_PROGRAM_READ: "affiliate_program.read",
    AFFILIATE_PROGRAM_WRITE: "affiliate_program.write",
    USER_DISCOUNTS_READ: "user_discounts.read",
    USER_DISCOUNTS_WRITE: "user_discounts.write",
    QUIZZES_READ: "quizzes.read",
    QUIZZES_WRITE: "quizzes.write",
    DISCOUNT_CODES_READ: "discount_codes.read",
    DISCOUNT_CODES_WRITE: "discount_codes.write",
    HELPDESK_READ: "helpdesk.read",
    HELPDESK_WRITE: "helpdesk.write",
    BLOG_READ: "blog.read",
    BLOG_WRITE: "blog.write",
    PRODUCTS_READ: "products.read",
    PRODUCTS_WRITE: "products.write",
    ORDERS_READ: "orders.read",
    ORDERS_WRITE: "orders.write",
    APPOINTMENTS_READ: "appointments.read",
    APPOINTMENTS_WRITE: "appointments.write",
    CHATS_READ: "chats.read",
    CHATS_WRITE: "chats.write",
    SETTLEMENTS_READ: "settlements.read",
    SETTLEMENTS_WRITE: "settlements.write",
    SURVEYS_READ: "surveys.read",
    SURVEYS_WRITE: "surveys.write",
    THERAPIST_RATES_READ: "therapist_rates.read",
    THERAPIST_RATES_WRITE: "therapist_rates.write",
    THERAPIST_RECOMMENDATIONS_READ: "therapist_recommendations.read",
    THERAPIST_RECOMMENDATIONS_WRITE: "therapist_recommendations.write",
    ONLINE_COURSES_READ: "online_courses.read",
    ONLINE_COURSES_WRITE: "online_courses.write",
    OFFICE_MESSAGES_READ: "office_messages.read",
    OFFICE_MESSAGES_WRITE: "office_messages.write",
    SERVICES_READ: "services.read",
    SERVICES_WRITE: "services.write"
});

// USER FILTER
export const UserFilterCustomRule = Object.freeze({
    USER_HAVING_PREPAID_WITHOUT_SCHEDULED_APPOINTMENT:
        "user_having_prepaid_without_scheduled_appointment"
});
export const UserFilterCustomRuleLabel = Object.freeze({
    [UserFilterCustomRule.USER_HAVING_PREPAID_WITHOUT_SCHEDULED_APPOINTMENT]:
        "Przedpłata bez um. wizyty"
});
export const UserFilterCustomRuleSelectItems = Object.values(UserFilterCustomRule).map(it => ({
    text: UserFilterCustomRuleLabel[it],
    value: it
}));
