var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-block-aslform"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"outlined":"","label":"Ikona","items":_vm.ContentBlockAnimatedSummaryListItemIconSelectItems,"hide-details":"auto"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20","color":"primary"}},[_vm._v(_vm._s(item.icon))]),_c('span',[_vm._v(" "+_vm._s(item.text)+" ")])],1)]}},{key:"selection",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"size":"24","color":"primary"}},[_vm._v(_vm._s(item.icon))])],1)]}}]),model:{value:(_vm.lv.icon),callback:function ($$v) {_vm.$set(_vm.lv, "icon", $$v)},expression:"lv.icon"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Tekst","value":_vm.lv.text ? _vm.getPossibleLogicExpressionOutputs(_vm.lv.text).join('/') : '<Brak>',"readonly":"","hide-details":"auto"},on:{"click":() => {
                        if (_vm.lv.text) {
                            _vm.expr_dialog_value = _vm.lv.text;
                        }
                        _vm.expr_dialog = true;
                    }}},[_c('div',{style:(_vm.lv.text
                            ? { transform: 'translateY(-2px)' }
                            : { transform: 'translateY(2px)' }),attrs:{"slot":"append"},slot:"append"},[(_vm.lv.text)?_c('v-btn',{attrs:{"icon":"","small":"","color":"grey","title":"Usuń obraz"},on:{"click":function($event){$event.stopPropagation();_vm.lv.text = ''}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-close ")])],1):_vm._e(),_c('v-tooltip',{attrs:{"right":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"18"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle ")])]}}])},[_vm._v(" Wyświetlany tekst możesz zdefiniować przy użyciu wyrażeń logicznych w oparciu o odpowiedzi na inne pytania Quizu. ")])],1)])],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"1280","scrollable":""},model:{value:(_vm.expr_dialog),callback:function ($$v) {_vm.expr_dialog=$$v},expression:"expr_dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Edycja wyświetlanego tekstu ")]),_c('v-divider'),(_vm.expr_dialog)?_c('v-card-text',{staticClass:"pt-5",staticStyle:{"max-height":"75vh"}},[_c('ExpressionsWizard',{ref:"expr_wizard",attrs:{"quiz-boards":_vm.quizBoards,"allowedActions":['plaintext']},model:{value:(_vm.expr_dialog_value),callback:function ($$v) {_vm.expr_dialog_value=$$v},expression:"expr_dialog_value"}})],1):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.expr_dialog = false}}},[_vm._v(" Anuluj ")]),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":_vm.saveExpr}},[_vm._v(" Zapisz ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }