var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-3 grey lighten-4 rounded"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{ref:"selected_action",attrs:{"outlined":"","dense":"","label":"Akcja","rules":[
                    v => {
                        if (v === '' || v == null) {
                            return 'Wybierz akcję do wykonania';
                        }
                        return true;
                    }
                ],"items":_vm.action_select_items,"hide-details":"auto"},on:{"change":() => {
                        _vm.action_value = '';
                        if (_vm.$refs.action_value_select) {
                            _vm.$refs.action_value_select.resetValidation();
                        }
                        _vm.emitChange();
                    }},model:{value:(_vm.selected_action),callback:function ($$v) {_vm.selected_action=$$v},expression:"selected_action"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(
                    _vm.selected_action === 'gotoboard' || _vm.selected_action === 'rendercontentflow'
                )?_c('v-select',{ref:"action_value_select",attrs:{"outlined":"","dense":"","label":_vm.selected_action === 'gotoboard' ? 'Plansza' : 'Grupa zawartości',"rules":[
                    v => {
                        if (v === '' || v == null) {
                            return 'Wybierz element, którego dotyczyć ma akcja';
                        }
                        if (_vm.action_value_select_items.findIndex(it => it.value == v) === -1) {
                            return 'Wybrany wcześniej element nie jest już dostępny';
                        }
                        return true;
                    }
                ],"items":_vm.action_value_select_items,"hide-details":"auto"},on:{"change":_vm.emitChange},model:{value:(_vm.action_value),callback:function ($$v) {_vm.action_value=$$v},expression:"action_value"}}):(_vm.selected_action !== 'submitquiz')?_c('v-text-field',{ref:"action_value_tfield",attrs:{"disabled":_vm.selected_action === '' || _vm.selected_action == null,"outlined":"","dense":"","label":"Tekst do wyświetlenia","rules":[
                    v => {
                        if (v === '') {
                            return 'To pole nie może być puste';
                        }
                        if (v.length > 127) {
                            return 'Maksymalna długość zwracanego tekstu to 127 znaków';
                        }
                        return true;
                    }
                ],"hide-details":"auto"},on:{"input":_vm.emitChange},model:{value:(_vm.action_value),callback:function ($$v) {_vm.action_value=$$v},expression:"action_value"}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }