<template>
    <div class="pa-3 grey lighten-4 rounded">
        <v-row dense>
            <v-col
                cols="12"
                sm="6"
            >
                <v-select
                    outlined
                    dense
                    label="Akcja"
                    v-model="selected_action"
                    ref="selected_action"
                    :rules="[
                        v => {
                            if (v === '' || v == null) {
                                return 'Wybierz akcję do wykonania';
                            }
                            return true;
                        }
                    ]"
                    :items="action_select_items"
                    hide-details="auto"
                    @change="
                        () => {
                            action_value = '';
                            if ($refs.action_value_select) {
                                $refs.action_value_select.resetValidation();
                            }
                            emitChange();
                        }
                    "
                />
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
                <v-select
                    v-if="
                        selected_action === 'gotoboard' || selected_action === 'rendercontentflow'
                    "
                    outlined
                    dense
                    :label="selected_action === 'gotoboard' ? 'Plansza' : 'Grupa zawartości'"
                    v-model="action_value"
                    ref="action_value_select"
                    :rules="[
                        v => {
                            if (v === '' || v == null) {
                                return 'Wybierz element, którego dotyczyć ma akcja';
                            }
                            if (action_value_select_items.findIndex(it => it.value == v) === -1) {
                                return 'Wybrany wcześniej element nie jest już dostępny';
                            }
                            return true;
                        }
                    ]"
                    :items="action_value_select_items"
                    hide-details="auto"
                    @change="emitChange"
                />
                <v-text-field
                    v-else-if="selected_action !== 'submitquiz'"
                    :disabled="selected_action === '' || selected_action == null"
                    outlined
                    dense
                    label="Tekst do wyświetlenia"
                    v-model="action_value"
                    ref="action_value_tfield"
                    :rules="[
                        v => {
                            if (v === '') {
                                return 'To pole nie może być puste';
                            }
                            if (v.length > 127) {
                                return 'Maksymalna długość zwracanego tekstu to 127 znaków';
                            }
                            return true;
                        }
                    ]"
                    hide-details="auto"
                    @input="emitChange"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        allowedActions: {
            type: Array,
            required: true
        },
        quizBoards: {
            required: true,
            type: Array
        },
        rendercontentflowBoardId: {
            type: String,
            default: ""
        },
        value: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            selected_action: "",
            action_value: ""
        };
    },

    computed: {
        action_select_items() {
            const A = [];

            if (this.allowedActions.indexOf("gotoboard") !== -1) {
                A.push({
                    text: "Wyświetl planszę Quizu",
                    value: "gotoboard"
                });
            }
            if (this.allowedActions.indexOf("submitquiz") !== -1) {
                A.push({
                    text: "Zakończ Quiz",
                    value: "submitquiz"
                });
            }
            if (this.allowedActions.indexOf("rendercontentflow") !== -1) {
                A.push({
                    text: "Wyświetl grupę zawartości",
                    value: "rendercontentflow"
                });
            }
            if (this.allowedActions.indexOf("plaintext") !== -1) {
                A.push({
                    text: "Wyświetl tekst",
                    value: "plaintext"
                });
            }

            return A;
        },
        action_value_select_items() {
            const A = [];

            if (this.selected_action === "gotoboard") {
                for (let i = 0; i < this.quizBoards.length; i++) {
                    A.push({
                        text: this.quizBoards[i].name,
                        value: this.quizBoards[i]._id
                    });
                }
            } else if (this.selected_action === "rendercontentflow") {
                // 1. Znajdujemy aktualnie wybraną do rendercontentflow planszę
                const QFITEM = this.quizBoards.find(it => it._id == this.rendercontentflowBoardId);
                if (QFITEM) {
                    const UNWCF = JSON.parse(QFITEM.content);
                    for (let i = 0; i < UNWCF.length; i++) {
                        A.push({
                            text: UNWCF[i].name,
                            value: UNWCF[i].oid
                        });
                    }
                }
            }

            return A;
        }
    },

    watch: {
        value: {
            handler() {
                if (this.value && this.value != "") {
                    if (this.value.indexOf("::") !== -1) {
                        const spl = this.value.split("::");
                        if (spl.length === 2 && this.actionIsAllowed(spl[0])) {
                            this.selected_action = spl[0];
                            this.action_value = spl[1];
                        }
                    } else if (this.actionIsAllowed("plaintext")) {
                        this.selected_action = "plaintext";
                        this.action_value = this.value;
                    } else if (this.actionIsAllowed("submitquiz")) {
                        this.selected_action = "submitquiz";
                        this.action_value = "";
                    }
                }
            },
            immediate: true
        }
    },

    methods: {
        actionIsAllowed(action) {
            return this.allowedActions.indexOf(action) !== -1;
        },
        emitChange() {
            this.$emit("input", this.getValue());
        },

        validate() {
            const A = [];

            A.push(this.$refs.selected_action.validate(true));

            if (this.selected_action === "plaintext") {
                A.push(this.$refs.action_value_tfield.validate(true));
            } else if (
                this.selected_action === "gotoboard" ||
                this.selected_action === "rendercontentflow"
            ) {
                A.push(this.$refs.action_value_select.validate(true));
            }

            return A.indexOf(false) === -1;
        },
        getValue() {
            if (this.selected_action === "plaintext") {
                return this.action_value;
            }
            if (this.selected_action === "submitquiz") {
                return this.selected_action;
            }
            return this.selected_action + "::" + this.action_value;
        }
    },

    mounted() {
        if (this.action_select_items.length === 1) {
            this.selected_action = this.action_select_items[0].value;
        }
    }
};
</script>
