import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import E404 from "../views/Error404.vue";
import store from "../store";
import waitForMs from "../helpers/waiters";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: Home
    },

    // AUTH ROUTES
    {
        path: "/auth/logowanie",
        name: "auth-login",
        component: () => import(/* webpackChunkName: "auth" */ "../views/auth/login.vue")
    },
    {
        path: "/auth/rejestracja",
        name: "auth-register",
        component: () => import(/* webpackChunkName: "auth" */ "../views/auth/register.vue")
    },

    // MY ACCOUNT
    {
        path: "/moje-konto",
        name: "my-account__main",
        component: () =>
            import(/* webpackChunkName: "my-account" */ "../views/my-account/index.vue")
    },

    // USERS MODULE
    {
        path: "/uzytkownicy",
        name: "users__list",
        component: () => import(/* webpackChunkName: "users" */ "../views/users/index.vue")
    },
    {
        path: "/uzytkownicy/:id",
        name: "users__edit",
        component: () => import(/* webpackChunkName: "users" */ "../views/users/edit.vue")
    },

    // APPOINTMENTS MODULE
    {
        path: "/lista-wizyt",
        name: "appointments__list",
        component: () =>
            import(/* webpackChunkName: "appointments" */ "../views/appointments/index.vue")
    },
    {
        path: "/lista-wizyt/:id",
        name: "appointments__details",
        component: () =>
            import(/* webpackChunkName: "appointments" */ "../views/appointments/details.vue")
    },

    // CALENDAR MODULE
    {
        path: "/kalendarz-wizyt",
        name: "calendar",
        component: () => import(/* webpackChunkName: "calendar" */ "../views/calendar/index.vue")
    },

    // USER SETTLEMENTS MODULE
    {
        path: "/rozliczenia-uzytkownikow",
        name: "user-settlements__list",
        component: () =>
            import(/* webpackChunkName: "user-settlements" */ "../views/user-settlements/index.vue")
    },

    // CHATS MODULE
    {
        path: "/czaty",
        name: "chats__list",
        component: () => import(/* webpackChunkName: "chats" */ "../views/chats/index.vue")
    },
    {
        path: "/czaty/:id",
        name: "chats__details",
        component: () => import(/* webpackChunkName: "chats" */ "../views/chats/details.vue")
    },

    // CHECKOUT SESSIONS MODULE
    {
        path: "/zamowienia",
        name: "checkout_sessions__list",
        component: () =>
            import(
                /* webpackChunkName: "checkout_sessions" */ "../views/checkout-sessions/index.vue"
            )
    },
    {
        path: "/zamowienia/:id",
        name: "checkout_sessions__edit",
        component: () =>
            import(
                /* webpackChunkName: "checkout_sessions" */ "../views/checkout-sessions/edit.vue"
            )
    },

    // RECOMMEND FRIENDS MODULE
    {
        path: "/lista-polecen",
        name: "recommend-friends__list",
        component: () =>
            import(
                /* webpackChunkName: "recommend_friends" */ "../views/recommend-friends/list.vue"
            )
    },
    {
        path: "/statystyki-polecen",
        name: "recommend-friends__stats",
        component: () =>
            import(
                /* webpackChunkName: "recommend_friends" */ "../views/recommend-friends/stats.vue"
            )
    },

    // SURVEYS MODULE
    {
        path: "/ankiety",
        name: "surveys__list",
        component: () => import(/* webpackChunkName: "surveys" */ "../views/surveys/index.vue")
    },
    {
        path: "/ankiety/:id",
        name: "surveys__edit",
        component: () => import(/* webpackChunkName: "surveys" */ "../views/surveys/edit.vue")
    },

    // SURVEY RESPONSES MODULE
    {
        path: "/wyniki-ankiet",
        name: "survey-responses__list",
        component: () =>
            import(/* webpackChunkName: "survey-responses" */ "../views/survey-responses/list.vue")
    },
    {
        path: "/wyniki-ankiet/statystyki",
        name: "survey-responses__stats",
        component: () =>
            import(/* webpackChunkName: "survey-responses" */ "../views/survey-responses/stats.vue")
    },
    {
        path: "/wyniki-ankiet/:id",
        name: "survey-responses__details",
        component: () =>
            import(
                /* webpackChunkName: "survey-responses" */ "../views/survey-responses/details.vue"
            )
    },

    // QUIZZES MODULE
    {
        path: "/quizy",
        name: "quizzes__list",
        component: () => import(/* webpackChunkName: "quizzes" */ "../views/quizzes/index.vue")
    },
    {
        path: "/quizy/:id",
        name: "quizzes__edit",
        component: () => import(/* webpackChunkName: "quizzes" */ "../views/quizzes/edit.vue")
    },

    // STANDALONE ORDERS MODULE
    {
        path: "/standalone-orders",
        name: "standalone-orders__list",
        component: () =>
            import(
                /* webpackChunkName: "standalone-orders" */ "../views/standalone-orders/index.vue"
            )
    },

    // THERAPIST RATES MODULE
    {
        path: "/oceny-terapeutow",
        name: "therapist_rates__list",
        component: () =>
            import(/* webpackChunkName: "therapist-rates" */ "../views/therapist-rates/index.vue")
    },

    // THERAPIST RECOMMENDATIONS MODULE
    {
        path: "/rekomendacje-wlasne",
        name: "therapist-recommendations__list",
        component: () =>
            import(
                /* webpackChunkName: "therapist-recommendations" */ "../views/therapist-recommendations/index.vue"
            )
    },

    // PRODUCTS MODULE
    {
        path: "/produkty",
        name: "products__list",
        component: () => import(/* webpackChunkName: "products" */ "../views/products/index.vue")
    },
    {
        path: "/produkty/:id",
        name: "products__edit",
        component: () => import(/* webpackChunkName: "products" */ "../views/products/edit.vue")
    },

    // DISCOUNT CODES MODULE
    {
        path: "/kody-znizkowe",
        name: "discount_codes__list",
        component: () =>
            import(/* webpackChunkName: "products" */ "../views/discount-codes/index.vue")
    },

    // QUIZ STATS MODULE
    {
        path: "/statystyki-quiz",
        name: "quizstats",
        component: () => import(/* webpackChunkName: "quizstats" */ "../views/quiz-stats/index.vue")
    },
    {
        path: "/statystyki-sesji-onboardingowych",
        name: "quizstats-onboarding",
        component: () =>
            import(
                /* webpackChunkName: "quizstats-onboarding" */ "../views/quiz-stats-onboarding/index.vue"
            )
    },

    // FAQ QUESTIONS MODULE
    {
        path: "/pytania-faq",
        name: "faq_questions__list",
        component: () =>
            import(/* webpackChunkName: "faq-questions" */ "../views/faq-questions/index.vue")
    },
    {
        path: "/pytania-faq/:id",
        name: "faq_questions__edit",
        component: () =>
            import(/* webpackChunkName: "faq-questions" */ "../views/faq-questions/edit.vue")
    },

    // WP BLOG MODULE
    {
        path: "/wp-blog",
        name: "wpblog__list",
        component: () => import(/* webpackChunkName: "wp-blog" */ "../views/wp-blog/index.vue")
    },
    {
        path: "/wp-blog/:id",
        name: "wpblog__details",
        component: () => import(/* webpackChunkName: "wp-blog" */ "../views/wp-blog/details.vue")
    },

    // ONLINE COURSES
    {
        path: "/szkolenia-online",
        name: "online-courses__list",
        component: () =>
            import(/* webpackChunkName: "online-courses" */ "../views/online-courses/index.vue")
    },
    {
        path: "/szkolenia-online/:id",
        name: "online-courses__details",
        component: () =>
            import(/* webpackChunkName: "online-courses" */ "../views/online-courses/details.vue")
    },

    // OFFICE MESSAGES
    {
        path: "/wiadomosci-z-biura",
        name: "office-messages__list",
        component: () =>
            import(/* webpackChunkName: "office-messages" */ "../views/office-messages/index.vue")
    },
    {
        path: "/wiadomosci-z-biura/:id",
        name: "office-messages__details",
        component: () =>
            import(/* webpackChunkName: "office-messages" */ "../views/office-messages/details.vue")
    },

    // GLOBAL SETTINGS
    {
        path: "/ustawienia-globalne",
        name: "global-settings__list",
        component: () =>
            import(/* webpackChunkName: "global-settings" */ "../views/global-settings/index.vue")
    },

    // RECRUITMENT
    {
        path: "/rekrutacja/archiwum-wnioskow",
        name: "recruitment__list",
        component: () =>
            import(/* webpackChunkName: "recruitment" */ "../views/recruitment/index.vue")
    },
    {
        path: "/rekrutacja/tablica-wnioskow",
        name: "recruitment__table",
        component: () =>
            import(/* webpackChunkName: "recruitment" */ "../views/recruitment/table.vue")
    },

    // HELPDESK MODULE
    {
        path: "/centrum-pomocy",
        component: () => import(/* webpackChunkName: "helpdesk" */ "../views/helpdesk/layout.vue"),

        children: [
            {
                path: "",
                name: "helpdesk__list",
                component: () =>
                    import(/* webpackChunkName: "helpdesk" */ "../views/helpdesk/index.vue")
            },
            {
                path: ":id",
                name: "helpdesk__details",
                component: () =>
                    import(/* webpackChunkName: "helpdesk" */ "../views/helpdesk/chat.vue")
            }
        ]
    },

    // TEST
    {
        path: "/test",
        name: "test",
        component: () => import(/* webpackChunkName: "test" */ "../views/test/index.vue")
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

const UNPROTECTED_PATHS = ["/auth/rejestracja", "/auth/logowanie", "/auth/odblokowanie-konta"];

router.beforeEach(async (to, from, next) => {
    // jeżeli apka się jeszcze nie zbootowała to grzecznie czekamy
    while (!store.state.app_booted) {
        await waitForMs(250);
    }

    // jeżeli użytkownik próbuje się dostać na zabezpieczony route to sprawdzamy jego uprawnienia - musi być tylko zalogowany i nie role == user, resztę załatwi UI i 401
    if (
        UNPROTECTED_PATHS.indexOf(to.path) == -1 &&
        (!store.getters["auth/isLoggedIn"] ||
            (store.getters["auth/isLoggedIn"] && store.getters["auth/userRole"] == "user"))
    ) {
        return next("/auth/logowanie?rback=" + encodeURIComponent(to.path));
    }

    return next();
});

export default router;
