<template>
    <div>
        <slot
            name="item"
            :item="item"
            v-for="item in d"
        ></slot>
    </div>
</template>

<script>
export default {
    props: {
        metadata: {
            type: [Object]
        }
    },

    data() {
        return {
            d: []
        };
    },

    watch: {
        metadata: {
            handler() {
                this.d = [];
                if (this.metadata) {
                    try {
                        if (this.metadata.width && this.metadata.height) {
                            this.d.push({
                                name: "Rozdzielczość",
                                value: `${this.metadata.width}x${this.metadata.height}px`
                            });
                        }
                        if (this.metadata.framerate) {
                            this.d.push({
                                name: "Framerate",
                                value: `${this.metadata.framerate} kl/sek`
                            });
                        }
                        if (this.metadata.duration) {
                            this.d.push({
                                name: "Czas trwania",
                                value: this.parseDuration(this.metadata.duration)
                            });
                        }
                        if (this.metadata.number_of_pages) {
                            this.d.push({
                                name: "Ilość stron",
                                value: this.metadata.number_of_pages
                            });
                        }
                    } catch (err) {
                        console.error(err);
                    }
                }
            },
            immediate: true
        }
    },

    methods: {
        parseDuration(duration) {
            if (duration < 60) {
                return `${duration} sek`;
            }
            if (duration < 60 * 60) {
                return `${Math.floor(duration / 60)} min ${duration % 60} sek`;
            }
            const min = duration % 3600;
            return `${Math.floor(duration / 3600)} godz ${Math.floor(min / 60)} min ${
                min % 60
            } sek`;
        }
    }
};
</script>
