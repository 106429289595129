<template>
    <router-link
        :to="to"
        class="admin-notification-tile"
        :class="{
            'admin-notification-tile--unread': notification.read === false
        }"
        @click.native="markAsRead"
    >
        <div class="admin-notification-tile__image">
            <v-avatar
                size="48"
                color="primary"
            >
                <template v-if="notification.subject_user">
                    <v-img
                        v-if="
                            notification.subject_user.profile_image &&
                            notification.subject_user.profile_image.url_sm != ''
                        "
                        :src="notification.subject_user.profile_image.url_sm"
                    />
                    <div
                        class="text-center white--text font-weight-regular"
                        v-else
                    >
                        {{ $store.getters["auth/userInitials"](notification.subject_user) }}
                    </div>
                </template>
                <v-img
                    v-else
                    src="@/assets/img/risify-avatar.png"
                />
            </v-avatar>
        </div>
        <div class="admin-notification-tile__content text-body-2">
            <template v-if="notification.name == AdminNotificationName.APPOINTMENT_HOST_ABSENCE_5M">
                <span class="admin-notification-tile__bold">{{
                    subject_user_name ? subject_user_name : "Terapeuta"
                }}</span>
                <span class="admin-notification-tile__bold"> spóźnia&nbsp;się</span> już
                5&nbsp;minut na&nbsp;umówioną wizytę.
            </template>
            <template
                v-else-if="notification.name == AdminNotificationName.APPOINTMENT_HOST_ABSENCE_10M"
            >
                <span class="admin-notification-tile__bold">{{
                    subject_user_name ? subject_user_name : "Terapeuta"
                }}</span>
                <span class="admin-notification-tile__bold"> spóźnia&nbsp;się</span> już
                10&nbsp;minut na&nbsp;umówioną wizytę.
            </template>
            <template v-else-if="notification.name == AdminNotificationName.NEW_HELPDESK_THREAD">
                <span class="admin-notification-tile__bold">{{
                    subject_user_name ? subject_user_name : "Użytkownik"
                }}</span>
                utworzył nowe
                <span class="admin-notification-tile__bold">zgłoszenie pomocy</span> i&nbsp;oczekuje
                odpowiedzi.
            </template>
            <template v-else-if="notification.name == AdminNotificationName.NEW_HELPDESK_MESSAGE">
                <span class="admin-notification-tile__bold">{{
                    subject_user_name ? subject_user_name : "Użytkownik"
                }}</span>
                wysłał nową wiadomość w&nbsp;swoim
                <span class="admin-notification-tile__bold">zgłoszeniu pomocy</span> i&nbsp;oczekuje
                odpowiedzi.
            </template>
            <template v-else-if="notification.name == AdminNotificationName.THUSAPPR_CHAT_MENTION">
                <span class="admin-notification-tile__bold">{{
                    subject_user_name ? subject_user_name : "Użytkownik"
                }}</span>
                prosi Administrację o&nbsp;<span class="admin-notification-tile__bold"
                    >włączenie&nbsp;się do&nbsp;rozmowy</span
                >
                na&nbsp;swoim czacie z&nbsp;terapeutą.
            </template>
            <template
                v-else-if="
                    notification.name ==
                    AdminNotificationName.APPOINTMENT_RESCHEDULE_PROPOSAL_EXPIRED
                "
            >
                <span class="admin-notification-tile__bold">
                    {{ subject_user_name ? subject_user_name : "Użytkownik" }}
                </span>
                <span class="admin-notification-tile__bold">nie&nbsp;odpowiedział</span>
                w&nbsp;wyznaczonym czasie
                <span class="admin-notification-tile__bold">na&nbsp;prośbę terapeuty</span>
                o&nbsp;zmianę terminu wizyty. Wymagana&nbsp;jest interwencja.
            </template>
            <template
                v-else-if="
                    notification.name == AdminNotificationName.PAYMENT_INTENT_STRIPE_REFUND_ERROR
                "
            >
                <span class="admin-notification-tile__bold">Zwrot płatności poprzez Stripe</span>
                zadysponowany do&nbsp;zamówienia zakończył&nbsp;się błędem. Wymagana&nbsp;jest
                manualna obsługa przypadku. Faktura korekta do&nbsp;nieudanej płatności pozostała
                wygenerowana.
            </template>
            <div class="mt-1 text-right text-caption grey--text">
                {{ formatDateRelativeToCurrentTime(new Date(notification.c_date)) }}
            </div>
        </div>
    </router-link>
</template>

<script>
import { formatDateRelativeToCurrentTime } from "../../helpers/formatters";
import {
    AdminNotificationName,
    AdminNotificationTargetObject
} from "../../enums/AdminNotification";

export default {
    props: {
        notification: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            formatDateRelativeToCurrentTime,

            AdminNotificationName
        };
    },

    computed: {
        subject_user_name() {
            if (this.notification && this.notification.subject_user !== undefined) {
                return `${this.notification.subject_user.first_name} ${this.notification.subject_user.last_name}`;
            }
            return "";
        },

        to() {
            if (this.notification.target_object === AdminNotificationTargetObject.APPOINTMENT) {
                return {
                    name: "appointments__details",
                    params: {
                        id: this.notification.target_id
                    }
                };
            } else if (
                this.notification.target_object === AdminNotificationTargetObject.HELPDESK_THREAD
            ) {
                return {
                    name: "helpdesk__details",
                    params: {
                        id: this.notification.target_id
                    }
                };
            } else if (this.notification.target_object === AdminNotificationTargetObject.CHAT) {
                return {
                    name: "chats__details",
                    params: {
                        id: this.notification.target_id
                    }
                };
            } else if (
                this.notification.target_object === AdminNotificationTargetObject.CHECKOUT_SESSION
            ) {
                return {
                    name: "checkout_sessions__edit",
                    params: {
                        id: this.notification.target_id
                    }
                };
            }
            return "/";
        }
    },

    methods: {
        async markAsRead() {
            if (this.notification.read) return;
            try {
                const R = await this.$axios.$post(
                    `/admin-notifications/${this.notification._id}/read`
                );
                this.$emit("notification-updated", R.admin_notification);
            } catch (err) {
                console.error(err);
            }
        }
    }
};
</script>
