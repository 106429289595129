var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",class:{
        'user-tile d-flex align-center text-decoration-none': true,
        'user-tile--clickable': _vm.clickDetails
    },attrs:{"to":_vm.to},on:{"click":_vm.showDetails}},[(_vm.value)?[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":192,"outlined":"","disabled":!_vm.contactPopover,"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(_vm.tag,_vm._g(_vm._b({tag:"component",staticClass:"user-tile d-flex align-center text-decoration-none",attrs:{"to":_vm.to}},'component',attrs,false),on),[_c('v-avatar',{class:{
                            'mr-2': _vm.computedAvatarSize > 16,
                            'mr-1': _vm.computedAvatarSize <= 16
                        },attrs:{"size":_vm.computedAvatarSize,"color":"primary"}},[(_vm.value && _vm.value.profile_image)?_c('v-img',{attrs:{"src":_vm.value.profile_image.url_sm}}):_c('div',{staticClass:"text-center white--text font-weight-medium",style:({
                                'font-size': `${Math.max(
                                    0.5,
                                    0.05 * (_vm.computedAvatarSize / 2)
                                )}rem`,
                                'line-height': 1
                            })},[_vm._v(" "+_vm._s(_vm.$store.getters["auth/userInitials"](_vm.value))+" ")])],1),_c('div',{staticClass:"text-body-2"},[_c('div',{staticClass:"font-weight-medium",staticStyle:{"color":"#434343"}},[_vm._v(" "+_vm._s(_vm.value.first_name)+" "+_vm._s(_vm.value.last_name ? _vm.value.last_name : "")+" "),(
                                    !_vm.hideNameDetails &&
                                    _vm.$store.state.auth.value &&
                                    _vm.$store.state.auth.value._id == _vm.value._id
                                )?_c('span',{staticClass:"font-weight-regular"},[_vm._v(" (Ty) ")]):_vm._e()]),_c('div',{staticClass:"grey--text text-caption",staticStyle:{"margin-top":"-4px"}},[(_vm.detailsKey == 'role')?[_vm._v(" "+_vm._s(_vm.user_role_labels[_vm.value.role])+" ")]:(_vm.value[_vm.detailsKey] !== undefined)?[_vm._v(" "+_vm._s(_vm.value[_vm.detailsKey])+" ")]:[_vm._v(" "+_vm._s(_vm.detailsKey)+" ")]],2)])],1)]}}],null,false,444984555)},[_c('v-card',[_c('v-card-title',{staticClass:"pa-2 px-4 text-caption"},[_vm._v(_vm._s(_vm.value.first_name)+" "+_vm._s(_vm.value.last_name))]),_c('v-divider'),_c('v-card-text',[_c('div',{staticClass:"text-subtitle-1 grey--text d-flex align-center",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.copyString(_vm.value.email)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"16"}},[_vm._v("mdi-at")]),_vm._v(" "+_vm._s(_vm.value.email)+" "),_c('v-btn',{attrs:{"icon":"","color":"grey","x-small":""}},[_c('v-icon',{attrs:{"size":"12"}},[_vm._v("mdi-content-copy")])],1)],1),(_vm.value.phone_cc && _vm.value.phone)?_c('div',{staticClass:"text-subtitle-1 grey--text d-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"16"}},[_vm._v("mdi-phone")]),_c('a',{attrs:{"href":`tel:${_vm.value.phone_cc}${_vm.value.phone}`}},[_vm._v(_vm._s(`+${_vm.value.phone_cc} ${_vm.value.phone}`))])],1):_vm._e()])],1)],1)]:[_c('v-skeleton-loader',{staticClass:"mr-2",attrs:{"width":_vm.computedAvatarSize,"height":_vm.computedAvatarSize,"type":"avatar"}}),_c('div',[_c('v-skeleton-loader',{attrs:{"width":"90","height":"12","type":"text"}}),_c('v-skeleton-loader',{staticClass:"mt-1",attrs:{"width":"60","height":"8","type":"text"}})],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }