<template>
    <v-dialog
        v-model="value"
        max-width="720"
        persistent
        scrollable
    >
        <v-card class="wkmm-fu">
            <v-card-title>
                <div>
                    Wgraj plik(i)
                    <div class="text-body-2 mt-1">
                        Folder docelowy:
                        <v-btn
                            outlined
                            rounded
                            color="grey darken-1"
                            small
                            class="ml-1"
                            style="text-transform: none"
                        >
                            <v-icon
                                class="mr-1"
                                size="16"
                                >mdi-folder</v-icon
                            >
                            {{ target ? target.name : "Folder główny" }}
                        </v-btn>
                    </div>
                </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text
                class="pt-5"
                style="max-height: 70vh"
            >
                <v-fade-transition mode="out-in">
                    <div
                        v-if="!whitelist_loaded"
                        key="loader"
                        class="py-10 text-center"
                    >
                        <v-progress-circular
                            color="primary"
                            width="2"
                            size="24"
                            indeterminate
                        ></v-progress-circular>
                    </div>
                    <div
                        v-else
                        key="content"
                    >
                        <!-- FILE PICKER -->
                        <div
                            class="wkmm-fu-fpicker"
                            v-if="!loading"
                        >
                            <div
                                class="wkmm-fu-fpicker__dropzone"
                                @click="$refs.file_input.click()"
                                @drop.prevent="onFilesDrop"
                                @dragenter.prevent
                                @dragleave.prevent
                                @dragover.prevent
                            >
                                <v-icon size="36">mdi-upload</v-icon>
                                <div class="text-caption mt-2">
                                    Kliknij, aby wybrać plik(i), lub upuść je tutaj
                                </div>
                            </div>
                            <input
                                type="file"
                                ref="file_input"
                                class="wkmm-fu-fpicker__finput"
                                @change="onFileInputChange"
                                multiple
                            />
                        </div>

                        <!-- FILES LIST -->
                        <div
                            class="wkmm-fu-flist mt-4"
                            v-if="files_to_upload.length > 0"
                        >
                            <div class="text-caption grey--text text-uppercase mb-1">
                                Wybrane pliki ({{ files_to_upload.length }})
                            </div>
                            <v-sheet
                                v-for="(f, ix) in files_to_upload"
                                :key="f._id"
                                class="wkmm-fu-ftu my-1 pa-3"
                                outlined
                                rounded="lg"
                            >
                                <div class="wkmm-fu-ftu__icon">
                                    <WkMediaManagerFileIcon :file-type="f.display_type" />
                                </div>
                                <div class="wkmm-fu-ftu__details">
                                    <div class="wkmm-fu-ftu__name text-truncate">
                                        {{ f.file_data.name }}
                                    </div>
                                    <div class="wkmm-fu-ftu__sdet mt-n1 text-caption grey--text">
                                        Plik {{ f.ext.toUpperCase() }} ·
                                        {{ formatFileSize(f.file_data.size) }}
                                    </div>
                                    <div class="wkmm-fu-ftu__status mt-2 grey--text text-caption">
                                        Status:
                                        <span
                                            class="text-uppercase"
                                            :class="{
                                                'error--text': f.status == 'error',
                                                'success--text': f.status == 'uploaded',
                                                'black--text':
                                                    f.status == 'queued' || f.status == 'uploading'
                                            }"
                                        >
                                            {{
                                                f.status == "queued"
                                                    ? "Gotowy do wgrania"
                                                    : f.status == "error"
                                                    ? "Niepowodzenie"
                                                    : f.status == "uploaded"
                                                    ? "Sukces"
                                                    : f.status == "uploading" &&
                                                      f.upload_progress < 100
                                                    ? "Przesyłanie..."
                                                    : "Przetwarzanie..."
                                            }}
                                        </span>

                                        <v-tooltip
                                            right
                                            v-if="f.status == 'error'"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                    color="error"
                                                    dark
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    size="16"
                                                    class="d-inline-block"
                                                    style="margin-top: -2px"
                                                >
                                                    mdi-information-outline
                                                </v-icon>
                                            </template>
                                            <span>{{ f.error }}</span>
                                        </v-tooltip>
                                    </div>
                                    <v-progress-linear
                                        color="success"
                                        :value="f.upload_progress"
                                        class="mt-1"
                                        v-if="f.status == 'uploading'"
                                    ></v-progress-linear>
                                </div>
                                <div
                                    class="wkmm-fu-ftu__actions"
                                    v-if="f.status == 'queued' || f.status == 'error'"
                                >
                                    <v-btn
                                        small
                                        icon
                                        color="error"
                                        @click="files_to_upload.splice(ix, 1)"
                                        title="Usuń z listy"
                                    >
                                        <v-icon size="20">mdi-trash-can</v-icon>
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </div>
                    </div>
                </v-fade-transition>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="grey lighten-1"
                    :disabled="loading"
                    @click="modelEmit(false)"
                >
                    Zamknij
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    :loading="loading"
                    :disabled="!something_to_upload"
                    @click="uploadFiles"
                >
                    Wgraj pliki
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { formatFileSize } from "@/helpers/formatters";
import WkMediaManagerFileIcon from "@/components/WkMediaManager/WkMediaManagerFileIcon.vue";

export default {
    components: {
        WkMediaManagerFileIcon
    },

    props: {
        value: {
            type: Boolean,
            default: false
        },
        target: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            loading: false,

            whitelist: [],
            whitelist_loaded: false,

            files_to_upload: [],

            formatFileSize
        };
    },

    computed: {
        something_to_upload() {
            return (
                this.files_to_upload.length > 0 &&
                this.files_to_upload.findIndex(it => it.status == "queued") !== -1
            );
        }
    },

    watch: {
        value(nv, ov) {
            if (nv && nv != ov) {
                this.fetchWhitelist();
            } else if (nv === false && nv != ov) {
                this.files_to_upload = [];
            }
        }
    },

    methods: {
        modelEmit(v) {
            this.$emit("input", v);
        },

        onFileInputChange(e) {
            const INP = e.target;
            if (INP.files && INP.files.length > 0) {
                for (let i = 0; i < INP.files.length; i++) {
                    this.addFile(INP.files[i]);
                }
                this.$refs.file_input.value = "";
            }
        },
        onFilesDrop(e) {
            if (e && e.dataTransfer && e.dataTransfer.files) {
                for (let i = 0; i < e.dataTransfer.files.length; i++) {
                    this.addFile(e.dataTransfer.files[i]);
                }
            }
        },

        addFile(file) {
            const O = {
                _id: `ftu_${Math.floor(Math.random() * 100000)}:${Date.now()}`,
                file_data: file,
                status: "queued",
                upload_progress: 0,
                error: ""
            };

            // przydzielenie display_type
            let dtype = "unknown";
            let fext = O.file_data.name.split(".");
            fext = fext.pop().toLowerCase();
            O.ext = fext;

            // walidacja na typ pliku
            const MATHED_FTYPE = this.whitelist.find(
                it =>
                    (it.mimetype == O.file_data.type || O.file_data.type == "") &&
                    it.ext.indexOf(fext) !== -1
            );
            // console.log(O.file_data.type, JSON.stringify(MATHED_FTYPE));
            if (!MATHED_FTYPE) {
                O.status = "error";
                O.error = "Nieobsługiwany typ pliku";
            } else {
                dtype = MATHED_FTYPE.display_type;
            }

            O.display_type = dtype;

            // walidacja na rozmiar pliku
            if (O.file_data.size > 67108864) {
                O.status = "error";
                O.error = "Maksymalny rozmiar pliku to 64MB";
            }

            this.files_to_upload.push(O);
        },

        async uploadFiles() {
            if (this.loading || !this.something_to_upload) return;
            this.loading = true;

            const FTU = this.files_to_upload.filter(it => it.status == "queued");

            for (let i = 0; i < FTU.length; i++) {
                const ix = this.files_to_upload.findIndex(it => it._id == FTU[i]._id);
                try {
                    this.files_to_upload.splice(ix, 1, {
                        ...this.files_to_upload[ix],
                        status: "uploading"
                    });

                    const FD = new FormData();
                    if (this.target) {
                        FD.append("parent_folder", this.target._id);
                    }
                    FD.append("file", FTU[i].file_data);

                    const r = await this.$axios.$post(`/mediamanager/files`, FD, {
                        onUploadProgress: prEv => {
                            this.files_to_upload.splice(ix, 1, {
                                ...this.files_to_upload[ix],
                                upload_progress: Math.ceil((prEv.loaded / prEv.total) * 100)
                            });
                        }
                    });
                    this.$emit("file-uploaded", r.mediamanager_file);

                    this.files_to_upload.splice(ix, 1, {
                        ...this.files_to_upload[ix],
                        status: "uploaded"
                    });
                } catch (err) {
                    this.files_to_upload.splice(ix, 1, {
                        ...this.files_to_upload[ix],
                        status: "error",
                        error: "Niepowodzenie wgywania pliku - błędny format lub rozmiar pliku"
                    });

                    console.error(err);
                }
            }

            this.loading = false;
        },

        async fetchWhitelist() {
            try {
                const r = await this.$axios.$get("/mediamanager/files/assets/files-whitelist");
                const A = [];

                for (let key in r.whitelist) {
                    A.push({
                        mimetype: key,
                        ext: r.whitelist[key].ext,
                        display_type: r.whitelist[key].d_type
                    });
                }

                this.whitelist = A;
                this.whitelist_loaded = true;
            } catch (err) {
                console.error(err);
            }
        }
    }

    // mounted() {
    //     if (!this.$store.state.initial_layout_set) return;
    // }
};
</script>
