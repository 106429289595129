
const countries = require("../countries.json");
export default {
    namespaced: true,

    state: () => ({
        APPLICATION_STATUSES: {
            CREATED: "created",
            PAYMENT_SETUP: "payment_setup",
            PAYMENT_REJECTED: "payment_rejected",
            PAID: "paid",
            PERSONAL_DATA: "personal_data",
            SURVEY: "survey",
            PROPERTY_COMPONENTS: "property_components",
            RECURRING_EXPENSES: "recurring_expenses",
            DEBTS: "debts",
            DOCUMENTS: "documents",
            VERIFICATION_ERROR: "verification_error",
            VERIFIED: "verified",
            GENERATED: "generated",
            SUBMITTED: "submitted",
            SUCCEEDED: "succeeded",
            REJECTED: "rejected",
            CANCELED: "canceled"
        },

        APPLICATION_STATUSES_ORDER: {
            created: 1,
            payment_setup: 2,
            payment_rejected: 3,
            paid: 4,
            personal_data: 5,
            survey: 6,
            property_components: 7,
            recurring_expenses: 8,
            debts: 9,
            documents: 10,
            verification_error: 11,
            verified: 12,
            generated: 13,
            submitted: 14,
            succeeded: 15,
            rejected: 16,
            canceled: 17
        },

        APPLICATION_STATUSES_LABELS: {
            created: "Szkic",
            payment_setup: "Płatności zatwierdzone",
            payment_rejected: "Niepowodzenie płatności",
            paid: "Opłacono",
            personal_data: "Dane osobowe",
            survey: "Ankieta",
            property_components: "Składniki majątku",
            recurring_expenses: "Wydatki cykliczne",
            debts: "Wierzytelności",
            documents: "Dokumenty",
            verification_error: "Niepowodzenie weryfikacji",
            verified: "Zweryfikowany",
            generated: "Wygenerowany",
            submitted: "Złożony w sądzie",
            succeeded: "Pozytywnie rozpatrzony",
            rejected: "Odrzucony przez sąd",
            canceled: "Anulowany"
        },
        APPLICATION_STATUSES_COLORS: {
            created: "blue-grey",
            payment_setup: "blue-grey",
            payment_rejected: "red darken-3",
            paid: "green darken-3",
            personal_data: "indigo darken-1",
            survey: "indigo darken-1",
            property_components: "indigo darken-1",
            recurring_expenses: "indigo darken-1",
            debts: "indigo darken-1",
            documents: "indigo darken-1",
            verification_error: "red darken-3",
            verified: "green darken-3",
            generated: "lime darken-2",
            submitted: "amber darken-2",
            succeeded: "green darken-3",
            rejected: "red darken-3",
            canceled: "grey darken-1"
        },

        APPLICATION_STATUSES_HINTS: {
            created:
                "Wniosek został wstępnie utworzony. Na tym etapie możliwa jest jego pełna edycja oraz zdefiniowanie trybu, ilości i rodzajów płatności.",
            payment_setup:
                "Płatności zostały zatwierdzone, Wniosek oczekuje na opłacenie pierwszej transzy przez Dłużnika",
            payment_rejected: "Wystąpił błąd płatności pierwszej transzy",
            paid: "Pierwsza transza płatności za Wniosek została pomyślnie opłacona. Na tym etapie Dłużnik może już rozpocząć korzystanie z panelpomocy.pl",
            personal_data: "Dłużnik uzupełnił i zatwierdził swoje dane osobowe",
            survey: "Dłużnik wypełnił i zatwierdził Ankietę",
            property_components: "Dłużnik dodał i zatwierdził wszystkie Składniki Majątku",
            recurring_expenses: "Dłużnik dodał i zatwierdził wszystkie Wydatki Cykliczne",
            debts: "Dłużnik dodał i zatwierdził wszystkich Wierzycieli",
            documents:
                "Dłużnik dodał i zatwierdził wszystkie Dokumenty. Wniosek oczekuje na weryfikację przez Prawnika.",
            verification_error: "Wniosek w toku weryfikacji został odrzucony przez Prawnika",
            verified: "Wniosek został pozytywnie zweryfikowany przez Prawnika",
            generated: "Wniosek został zatwierdzony i wygenerowany",
            submitted: "Wniosek został złożony w odpowiednim sądzie",
            succeeded: "Wniosek został pozytywnie rozpatrzony przez sąd",
            rejected: "Wniosek został odrzucony przez sąd",
            canceled: "Wniosek został anulowany"
        },

        APPLICATION_CONFIRMATIONS_LABELS: {
            payments: "Zatwierdzenie Płatności",
            debtor_personal_data: "Zatwierdzenie danych osobowych Dłużnika",
            survey: "Zatwierdzenie Ankiety",
            property_components: "Zatwierdzenie Składników Majątku",
            recurring_expenses: "Zatwierdzenie Wydatków Cyklicznych",
            debts: "Zatwierdzenie Wierzytelności",
            documents: "Zatwierdzenie Dokumentów",
            verified: "Zweryfikowano i dopuszczono do przygotowania",
            generated: "Przygotowano i udostępniono Klientowi",
            submitted: "Złożono w sądzie",
            succeeded: "Wniosek rozpatrzony pomyślnie",
            rejected: "Wniosek odrzucony przez sąd"
        }
    }),

    getters: {
        getParsedDebtorAddress: () => address => {
            if (!address) return "-";
            return `${address.street} ${address.house_no}${
                address.apartment_no ? "/" + address.apartment_no : ""
            }, ${address.zip_code} ${address.city}, ${
                countries.find(item => item.alpha2 == address.country)?.name?.pl || "-"
            }`;
        },
        getStatusPriority: state => status => {
            return state.APPLICATION_STATUSES_ORDER[status];
        }
    }
};
