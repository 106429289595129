export const REGEXES = Object.freeze({
    EMAIL: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    PHONE_NUMBER: /^[0-9]{9,15}$/,
    PHONE_COUNTRY_CODE: /^\+[0-9\-]{1,7}$/,
    ZIP_CODE_PL: /^[0-9]{2}-[0-9]{3}$/,
    ZIP_CODE_GENERAL: /^[A-Za-z0-9_-\s\/]{3,15}$/,
    TAX_NUMBER_GENERAL: /^[A-Za-z]{2}[0-9A-Za-z\-\/]{3,29}$/,
    MONGO_ID: /^[a-f\d]{24}$/i,
    SHA512: /^[a-fA-F0-9]{128}$/,
    QUIZ_CHART_CV_TAG_REGEX: /^(chart_cv:([A-Za-z0-9_]+)(:[^:\|]+)?)\|(sum|avg)$/
});

export function validatePESEL(pesel) {
    let full_year = pesel.slice(0, 2);

    let month = parseInt(pesel.slice(2, 4), 10);
    if (month > 80) {
        full_year = `18${full_year}`;
        month -= 80;
    } else if (month > 60) {
        full_year = `22${full_year}`;
        month -= 60;
    } else if (month > 40) {
        full_year = `21${full_year}`;
        month -= 40;
    } else if (month > 20) {
        full_year = `20${full_year}`;
        month -= 20;
    } else {
        full_year = `19${full_year}`;
    }

    // Add leading zero to month if needed
    if (month < 10) {
        month = `0${month}`;
    }

    // Check date validity
    const date = new Date(`${full_year}-${month}-${pesel.slice(4, 6)}`);
    if (date == "Invalid Date") {
        return false;
    }

    if (
        date.getFullYear() != full_year ||
        ("0" + (date.getMonth() + 1)).slice(-2) != month ||
        ("0" + date.getDate()).slice(-2) != pesel.slice(4, 6)
    ) {
        return false;
    }

    // Calculate last digit by mulitplying with odd one-digit numbers except 5
    let checksum = 0;
    let multiplier = 1;
    for (let i = 0; i < pesel.length - 1; i++) {
        checksum += (parseInt(pesel[i], 10) * multiplier) % 10;
        multiplier += 2;
        if (multiplier > 10) {
            multiplier = 1;
        } else if (multiplier === 5) {
            multiplier += 2;
        }
    }
    checksum = 10 - (checksum % 10);
    return checksum === parseInt(pesel[10], 10);
}

export function validatePassword(password) {
    let valid = true;
    if (!password || password.length < 8)
        valid = "Hasło powinno składać się z co najmniej 8 znaków";
    else if (password.length > 512) valid = "Hasło powinno składać się z maksymalnie 512 znaków";
    else if (!/[a-ząćęłńóśżźàâæçéèêëîïôœùûüÿöäß]+/.test(password))
        valid = "Hasło powinno zawierać małą literę";
    else if (!/[A-ZĄĆĘŁŃÓŚŻŹÀÂÆÇÉÈÊËÎÏÔŒÙÛÜŸÖÄẞ]+/.test(password))
        valid = "Hasło powinno zawierać wielką literę";
    else if (!/[0-9]+/.test(password)) valid = "Hasło powinno zawierać cyfrę";
    return valid;
}

export function validateYYYYMMDD(d) {
    if (!d) return false;

    let valid = true;

    // 1. walidacja na bazową zgodność formatu
    if (!/^[1-9]\d{3}-((0[1-9])|(1[0-2]))-([0-2][0-9]|3[0-1])$/.test(d)) valid = false;

    // 2. dodatkowa walidacja na ilość dni w miesiącu
    const [year, month, day] = d.split("-");
    if (day === "00" || day > new Date(year, month, 0).getDate()) valid = false;

    return valid;
}

export function validateGeneralPhoneNumber(num, min_length = 9, max_length = 15) {
    let valid = true;
    const PNUM = num.replace(/[^0-9]/g, ""); // normalizacja
    const STARTS_WITH_PLUS = num.length > 0 && num[0] === "+";

    // 1. Is required
    if (num === undefined || num === "") {
        valid = "To pole jest wymagane";
    }
    // 2. Numer musi mieć min_length-max_length znaków długości
    else if (
        PNUM.length < (STARTS_WITH_PLUS ? min_length + 1 : min_length) ||
        PNUM.length > (STARTS_WITH_PLUS ? max_length - 1 : max_length)
    ) {
        valid = `Numer musi składać się z ${min_length}-${max_length} cyfr`;
    }
    // 3. Numer może składać się tylko z określonych znaków
    else if (!/[^0-9 +-]/.test(num)) {
        valid =
            "Numer telefonu może składać się tylko z cyfr poprzedzonych opcjonalnym znakiem '+'";
    }

    return valid;
}

export function validatePolishPhoneNumber(num) {
    if (!num) return false;

    if (!/^\d{9}$/.test(num)) {
        return false;
    }

    const VALID_STARTING_DIGITS = [
        "45",
        "50",
        "51",
        "53",
        "57",
        "60",
        "66",
        "69",
        "72",
        "73",
        "78",
        "79",
        "88"
    ];

    if (VALID_STARTING_DIGITS.indexOf(num.slice(0, 2)) === -1) {
        return false;
    }
    return true;
}

export function validatePhoneNumber(value, country_code = "48") {
    if (!value || !country_code) return false;

    if (country_code === "48") {
        return validatePolishPhoneNumber(value);
    } else {
        if (!new RegExp(`^\\d{9,${15 - country_code.length}}$`).test(value)) return false;
    }
    return true;
}

export function validateNIP(value) {
    if (/^[0-9]{10}$/.test(value) && value != "0000000000") {
        // NIP PL
        const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        const NIP = value.split("");
        let SUM = 0;
        for (let nnn = 0; nnn < 9; nnn++) SUM += parseInt(NIP[nnn]) * weights[nnn];
        if (SUM % 11 != NIP[9]) return false;

        return true;
    } else {
        // invalid NIP
        return false;
    }
}
