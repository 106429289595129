var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"650","scrollable":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[(_vm.value)?_c('v-card',{staticClass:"wkmm-fs"},[_c('v-card-title',[_c('div',[_vm._v(" Przenieś \""+_vm._s(_vm.subjectName)+"\" "),_c('div',{staticClass:"text-body-2 mt-1"},[_vm._v(" Obecna lokalizacja: "),_c('v-btn',{staticClass:"ml-1",staticStyle:{"text-transform":"none"},attrs:{"outlined":"","rounded":"","color":"grey darken-1","small":""},on:{"click":function($event){return _vm.loadFolder(_vm.currentFolder._id)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"16"}},[_vm._v("mdi-folder")]),_vm._v(" "+_vm._s(_vm.currentFolder.name)+" ")],1)],1)])]),_c('v-divider'),_c('v-card-text',{staticClass:"pt-5",staticStyle:{"max-height":"70vh"}},[_c('div',{staticClass:"py-2 text-body-1 black--text"},[(_vm.loaded_folder)?_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","small":"","color":"black"},on:{"click":function($event){return _vm.loadFolder(_vm.loaded_folder.parent_folder)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-arrow-left")])],1):_vm._e(),_vm._v(" "+_vm._s(_vm.loaded_folder ? _vm.loaded_folder.name : "Folder główny")+" ")],1),_c('v-divider'),_c('div',{staticClass:"mt-2",staticStyle:{"overflow-y":"auto","max-height":"50vh"}},[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[(_vm.loading_folder)?_c('div',{key:"loader"},_vm._l((5),function(i){return _c('v-skeleton-loader',{key:i,attrs:{"type":"list-item"}})}),1):_c('div',{key:"content"},[(_vm.loaded_folder == null)?_c('div',[_c('WkMediaManagerFolderSelectEntity',{attrs:{"entity-type":"folder","item":{ _id: null },"selected-id":_vm.selected_folder_id},on:{"folder-select":id => {
                                        if (_vm.selected_folder_id != id) {
                                            _vm.selected_folder_id = id;
                                        } else {
                                            _vm.selected_folder_id = '';
                                        }
                                    }}}),_c('div',{staticClass:"py-2"},[_c('v-divider')],1)],1):_vm._e(),_vm._l((_vm.folders),function(entry){return _c('WkMediaManagerFolderSelectEntity',{key:'Folder:' + entry._id,staticClass:"mb-1",attrs:{"entity-type":"folder","item":entry,"selected-id":_vm.selected_folder_id,"disabled":entry._id == _vm.subjectId},on:{"folder-select":id => {
                                    if (_vm.selected_folder_id != id) {
                                        _vm.selected_folder_id = id;
                                    } else {
                                        _vm.selected_folder_id = '';
                                    }
                                },"open-folder":_vm.loadFolder}})}),_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.folderIoCb),expression:"folderIoCb"}],staticStyle:{"height":"1px"}}),(_vm.folders_loading)?_c('div',{staticClass:"py-2 text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"16","width":"2"}})],1):_vm._e(),_vm._l((_vm.files),function(entry){return _c('WkMediaManagerFolderSelectEntity',{key:'File:' + entry._id,staticClass:"mb-1",attrs:{"entity-type":"file","item":entry,"disabled":""}})}),_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.filesIoCb),expression:"filesIoCb"}],staticStyle:{"height":"1px"}}),(_vm.files_loading)?_c('div',{staticClass:"py-2 text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"16","width":"2"}})],1):_vm._e(),(_vm.folders.length == 0 && _vm.files.length == 0)?_c('div',{staticClass:"text-caption grey--text"},[_vm._v(" Folder jest pusty ")]):_vm._e()],2)])],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"grey lighten-1","disabled":_vm.loading},on:{"click":function($event){return _vm.modelEmit(false)}}},[_vm._v(" Anuluj ")]),_c('v-btn',{attrs:{"text":"","color":"primary","loading":_vm.loading,"disabled":_vm.selected_folder_id == ''},on:{"click":function($event){return _vm.$emit('folder-selected', _vm.selected_folder_id)}}},[_vm._v(" Przenieś ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }