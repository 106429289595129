var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
        'user-detailed-tile align-center text-decoration-none': true,
        'user-detailed-tile--clickable': _vm.clickDetails && _vm.copyable
    },on:{"click":_vm.showDetails}},[(_vm.id)?[_c('div',{staticClass:"text-body-2 d-flex"},[_c('v-avatar',{class:{
                    'mr-2': _vm.computedAvatarSize > 16,
                    'mr-1': _vm.computedAvatarSize <= 16
                },attrs:{"size":_vm.computedAvatarSize,"color":"primary"}},[(_vm.profileImage)?_c('v-img',{attrs:{"src":_vm.profileImage}}):_c('div',{staticClass:"text-center white--text font-weight-medium",style:({
                        'font-size': `${Math.max(0.5, 0.05 * (_vm.computedAvatarSize / 2))}rem`,
                        'line-height': 1
                    })},[_vm._v(" "+_vm._s(_vm.computedInitials)+" ")])],1),_c('div',[(_vm.title)?_c('div',{staticClass:"grey--text text-caption",staticStyle:{"margin-top":"-4px"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('div',{staticClass:"font-weight-medium",staticStyle:{"color":"#434343"}},[_vm._v(" "+_vm._s(_vm.name)+" "),(
                            !_vm.hideNameDetails &&
                            _vm.$store.state.auth.value &&
                            _vm.$store.state.auth.value._id == _vm.id
                        )?_c('span',{staticClass:"font-weight-regular"},[_vm._v(" (Ty) ")]):_vm._e()])])],1),_c('div',{staticClass:"text-body-2"},[_c('div',{staticClass:"grey--text text-caption d-block",staticStyle:{"cursor":"pointer","width":"max-content"},on:{"click":_vm.handleEmailClick}},[_vm._v(" "+_vm._s(_vm.email)+" ")]),(_vm.phone && _vm.phone.phone_cc && _vm.phone.phone)?_c('div',{staticClass:"grey--text text-caption",staticStyle:{"cursor":"pointer","width":"max-content"},on:{"click":_vm.handlePhoneClick}},[_vm._v(" "+_vm._s(`+${_vm.phone.phone_cc} ${_vm.phone.phone}`)+" ")]):_vm._e()])]:[_c('v-skeleton-loader',{staticClass:"mr-2",attrs:{"width":_vm.computedAvatarSize,"height":_vm.computedAvatarSize,"type":"avatar"}}),_c('div',[_c('v-skeleton-loader',{attrs:{"width":"90","height":"12","type":"text"}}),_c('v-skeleton-loader',{staticClass:"mt-1",attrs:{"width":"60","height":"8","type":"text"}})],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }