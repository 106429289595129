var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wkmm-fse py-1 rounded-lg pa-2",class:{
        'wkmm-fse--disabled': _vm.disabled,
        'grey lighten-4': !_vm.is_selected,
        'indigo lighten-5': _vm.is_selected
    },attrs:{"title":_vm.title},on:{"click":() => {
            if (_vm.disabled) return;
            if (_vm.entityType == 'folder') {
                this.$emit('folder-select', _vm.item._id);
            }
        }}},[_c('div',{staticClass:"wkmm-fse__icon"},[(_vm.entityType == 'folder' && _vm.item._id != null)?_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-folder")]):(_vm.entityType == 'folder' && _vm.item._id == null)?_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-folder-home")]):(_vm.entityType == 'file')?_c('WkMediaManagerFileIcon',{attrs:{"file-type":_vm.item.display_type,"size":"20"}}):_vm._e()],1),_c('div',{staticClass:"wkmm-fse__name text-truncate"},[_vm._v(" "+_vm._s(_vm.item._id === null ? "Umieść w Folderze głównym" : _vm.item.name)+" ")]),(_vm.entityType == 'folder' && _vm.item._id != null)?_c('div',{staticClass:"wkmm-fse__actions"},[_c('v-btn',{attrs:{"small":"","icon":"","color":"secondary","title":"Przejdź do zawartości"},on:{"click":function($event){return _vm.$emit('open-folder', _vm.item._id)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-chevron-right")])],1)],1):_vm._e(),(_vm.entityType == 'folder' && !_vm.disabled)?_c('div',{staticClass:"wkmm-fse__selovrl",class:{ 'wkmm-fse__selovrl--persistent': _vm.is_selected }},[_c('div',{staticClass:"wkmm-fse__selicon",class:{ 'grey lighten-4': !_vm.is_selected, 'indigo lighten-5': _vm.is_selected }},[(_vm.is_selected)?_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-check-circle-outline")]):_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-circle-outline")])],1)]):_vm._e(),(_vm.disabled)?_c('div',{staticClass:"wkmm-fse__disovrl"}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }