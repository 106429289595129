<template>
    <v-menu
        offset-y
        max-width="350"
        min-width="300"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-badge
                color="error"
                overlap
                offset-x="24"
                offset-y="24"
                :value="unread_count > 0 && items_loaded"
                :content="unread_count > 9 ? '9+' : unread_count"
            >
                <v-btn
                    color="grey"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    title="Powiadomienia systemowe"
                >
                    <v-icon>mdi-bell</v-icon>
                </v-btn>
            </v-badge>
        </template>
        <template v-slot:default>
            <template v-if="!items_loaded">
                <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
                <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
                <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
            </template>
            <template v-else>
                <div style="max-height: 460px; overflow-y: auto">
                    <div
                        v-if="items.length > 0"
                        class="white"
                    >
                        <AdminNotificationTile
                            v-for="item in items"
                            :key="item._id"
                            :notification="item"
                            @notification-updated="
                                it => {
                                    const ix = items.findIndex(item => item._id === it._id);
                                    if (ix !== -1) {
                                        items.splice(ix, 1, it);
                                    }
                                }
                            "
                        />
                        <div
                            style="height: 1px; margin-top: -1px"
                            v-intersect.quiet="onIntersect"
                        ></div>
                        <div
                            class="text-center pt-1"
                            style="overflow: hidden"
                            v-if="items_loading"
                        >
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                size="20"
                                width="2"
                            ></v-progress-circular>
                        </div>
                    </div>
                    <v-list v-else>
                        <v-list-item class="grey--text text-caption">
                            Nie otrzymano jeszcze żadnego powiadomienia
                        </v-list-item>
                    </v-list>
                </div>
            </template>
        </template>
    </v-menu>
</template>

<script>
import { IoEvent } from "../../enums/SocketIo";
import AdminNotificationTile from "./AdminNotificationTile.vue";
import { playNewNotificationSound } from "@/helpers/sounds";

export default {
    components: {
        AdminNotificationTile
    },

    data() {
        return {
            items: [],
            items_loading: false,
            items_loaded: false,

            pagination_current_page: 1,
            pagination_number_of_pages: 1,
            pagination_number_of_items: 0,

            marking_all_as_read: false
        };
    },

    computed: {
        unread_count() {
            return this.items.filter(i => i.read === false).length;
        }
    },

    methods: {
        onIntersect(entries, observer, isIntersecting) {
            if (isIntersecting && this.pagination_current_page < this.pagination_number_of_pages) {
                this.pagination_current_page += 1;
                this.fetchData();
            }
        },
        async fetchData() {
            if (this.items_loading) return;

            this.items_loading = true;

            try {
                const r = await this.$axios.$get(
                    `/admin-notifications/?page=${this.pagination_current_page}&items_per_page=32`
                );

                for (let i = 0; i < r.admin_notifications.length; i++) {
                    if (this.items.findIndex(l => l._id == r.admin_notifications[i]._id) === -1) {
                        this.items.push(r.admin_notifications[i]);
                    }
                }
                // console.log(r);

                this.pagination_current_page = r.pagination.current_page;
                this.pagination_number_of_pages = r.pagination.number_of_pages;
                this.pagination_number_of_items = r.pagination.number_of_items;

                this.items_loaded = true;
            } catch (err) {
                console.error(err);
            }

            this.items_loading = false;
        },

        // SOCKETS
        socketOnNotificationCreated(payload) {
            if (this.items.findIndex(it => it._id == payload.admin_notification._id) === -1) {
                this.items.unshift(payload.admin_notification);
                playNewNotificationSound();
            }
        },
        socketOnNotificationUpdated(payload) {
            const ix = this.items.findIndex(it => it._id == payload.admin_notification._id);

            if (ix === -1) return;

            this.items.splice(ix, 1, {
                ...this.items[ix],
                ...payload.admin_notification
            });
        }
    },

    mounted() {
        if (!this.$store.state.initial_layout_set) return;

        this.fetchData();

        this.$io.on(IoEvent.ADMIN_NOTIFICATION_CREATED, this.socketOnNotificationCreated);
        this.$io.on(IoEvent.ADMIN_NOTIFICATION_UPDATED, this.socketOnNotificationUpdated);
    },

    beforeDestroy() {
        this.$io.off(IoEvent.ADMIN_NOTIFICATION_CREATED, this.socketOnNotificationCreated);
        this.$io.off(IoEvent.ADMIN_NOTIFICATION_UPDATED, this.socketOnNotificationUpdated);
    }
};
</script>
