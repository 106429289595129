<template>
    <v-icon
        :size="size"
        :color="icon_color"
    >
        {{ icon_name }}
    </v-icon>
</template>

<script>
export default {
    props: {
        size: {
            type: [String, Number],
            default: "24"
        },
        fileType: {
            type: String,
            default: "unknown"
        }
    },

    computed: {
        icon_color() {
            switch (this.fileType) {
                case "image":
                    return "red darken-4";
                case "video":
                    return "red darken-4";
                case "sound":
                    return "indigo";
                case "word":
                    return "blue darken-3";
                case "excel":
                    return "green darken-2";
                case "powerpoint":
                    return "red darken-4";
                case "pdf":
                    return "red darken-4";
                case "archive":
                    return "grey";
                default:
                    return "grey";
            }
        },
        icon_name() {
            switch (this.fileType) {
                case "image":
                    return "mdi-file-image";
                case "video":
                    return "mdi-movie-open";
                case "sound":
                    return "mdi-file-music";
                case "word":
                    return "mdi-file-word";
                case "excel":
                    return "mdi-file-excel";
                case "powerpoint":
                    return "mdi-file-powerpoint";
                case "pdf":
                    return "mdi-file-pdf-box";
                case "archive":
                    return "mdi-zip-box";
                default:
                    return "mdi-file";
            }
        }
    }
};
</script>
