<template>
    <div
        v-if="lv != null"
        class="expressions-wizard-condition pa-2 grey lighten-4 rounded"
    >
        <v-row dense>
            <v-col
                cols="12"
                sm="4"
            >
                <v-select
                    outlined
                    dense
                    label="Źródło danych"
                    :items="[
                        {
                            text: 'Odpowiedź na pytanie',
                            value: 'quiz_board'
                        },
                        {
                            text: 'Wartość wykresu',
                            value: 'chart_cv'
                        }
                    ]"
                    hide-details="auto"
                    v-model="subject_type"
                    @change="emitChange"
                />
                <v-select
                    outlined
                    dense
                    label="Plansza"
                    :items="quiz_board_select_items"
                    hide-details="auto"
                    v-model="lv.subject"
                    ref="subject_select"
                    :rules="[
                        v => {
                            if (v === '' || v == null) {
                                return 'Wybierz planszę, której ma dotyczyć warunek';
                            }
                            return true;
                        }
                    ]"
                    @change="emitChange"
                    v-if="subject_type === 'quiz_board'"
                    class="mt-2"
                />
                <v-text-field
                    outlined
                    dense
                    label="Tag wykresu"
                    hide-details="auto"
                    v-model="lv.subject"
                    ref="subject_select"
                    :rules="[
                        v => {
                            if (!v || !REGEXES.QUIZ_CHART_CV_TAG_REGEX.test(v)) {
                                return 'Podany tag nie spełnia wymagań składniowych';
                            }
                            return true;
                        }
                    ]"
                    @input="emitChange"
                    v-else-if="subject_type === 'chart_cv'"
                    class="mt-2"
                />
            </v-col>
            <v-col
                cols="12"
                sm="3"
            >
                <v-select
                    outlined
                    dense
                    label="Warunek"
                    :items="condition_select_items"
                    hide-details="auto"
                    v-model="lv.exp_type"
                    ref="exp_type_select"
                    @change="
                        () => {
                            onExpTypeChange();
                            emitChange();
                        }
                    "
                    :rules="[
                        v => {
                            if (v === '' || v == null) {
                                return 'Wybierz warunek';
                            }
                            return true;
                        }
                    ]"
                />
            </v-col>
            <v-col
                cols="12"
                sm="5"
            >
                <v-select
                    v-if="predefined_answers_qbtypes.indexOf(selected_quiz_board_type) !== -1"
                    outlined
                    dense
                    label="Wartość"
                    v-model="lv.answer"
                    hide-details="auto"
                    :multiple="multiple_answers_allowed"
                    :items="answers_select_items"
                    ref="answer_select"
                    :rules="[
                        v => {
                            if (multiple_answers_allowed) {
                                if (v.length === 0) {
                                    return 'Wybierz przynajmniej jedną opcję';
                                }
                            } else {
                                if (v === '' || v == null) {
                                    return 'Wybierz jedną z opcji';
                                }
                            }

                            return true;
                        }
                    ]"
                    @change="emitChange"
                />
                <v-text-field
                    v-else
                    outlined
                    dense
                    label="Wartość"
                    v-model="lv.answer"
                    hide-details="auto"
                    ref="answer_tfield"
                    :rules="[
                        v => {
                            if (v === '') {
                                return 'To pole nie może być puste';
                            }
                            if (v.length > 31) {
                                return 'Maksymalna długość pola to 31 znaków';
                            }

                            return true;
                        }
                    ]"
                    @input="emitChange"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { QuizIfExpressionType } from "../../enums/Quiz";
import { QuizBoardType } from "../../enums/QuizBoard";
import { REGEXES } from "../../helpers/validators";

export default {
    name: "ExpressionsWizardCondition",

    props: {
        quizBoards: {
            type: Array,
            required: true
        },
        value: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            lv: null,
            subject_type: "", // quiz_board | chart_cv

            condition_select_items: [
                {
                    text: "jest dokładnie taka",
                    value: QuizIfExpressionType.EQ
                },
                {
                    text: "jest różna od",
                    value: QuizIfExpressionType.NEQ
                },
                {
                    text: "zawiera się w",
                    value: QuizIfExpressionType.IN
                },
                {
                    text: "nie zawiera się w",
                    value: QuizIfExpressionType.NIN
                },
                {
                    text: "jest równa (jako liczba)",
                    value: QuizIfExpressionType.NUMBER_EQ
                },
                {
                    text: "jest mniejsza od (jako liczba)",
                    value: QuizIfExpressionType.NUMBER_LT
                },
                {
                    text: "jest mniejsza lub równa (jako liczba)",
                    value: QuizIfExpressionType.NUMBER_LTE
                },
                {
                    text: "jest większa od (jako liczba)",
                    value: QuizIfExpressionType.NUMBER_GT
                },
                {
                    text: "jest większa lub równa (jako liczba)",
                    value: QuizIfExpressionType.NUMBER_GTE
                }
            ],
            predefined_answers_qbtypes: [
                QuizBoardType.QUESTION_SINGLE_CHOICE,
                QuizBoardType.QUESTION_MULTIPLE_CHOICE,
                QuizBoardType.QUESTION_NPS
            ],

            REGEXES
        };
    },

    computed: {
        quiz_board_select_items() {
            return this.quizBoards
                .filter(
                    it =>
                        it.type !== QuizBoardType.STATIC_CONTENT &&
                        it.type !== QuizBoardType.QUESTION_OPEN
                )
                .map(it => {
                    return {
                        text: it.name,
                        value: it._id
                    };
                });
        },

        selected_quiz_board_type() {
            if (this.subject_type === "quiz_board" && this.lv.subject != "") {
                const EL = this.quizBoards.find(it => it._id == this.lv.subject);
                if (EL) return EL.type;
            }
            return "";
        },

        multiple_answers_allowed() {
            return (
                this.lv &&
                (this.lv.exp_type === QuizIfExpressionType.IN ||
                    this.lv.exp_type === QuizIfExpressionType.NIN)
            );
        },

        answers_select_items() {
            const A = [];
            if (this.selected_quiz_board_type === QuizBoardType.QUESTION_NPS) {
                for (let i = 1; i <= 10; i++) {
                    A.push({
                        text: i.toString(),
                        value: i.toString()
                    });
                }
            } else if (
                this.selected_quiz_board_type === QuizBoardType.QUESTION_SINGLE_CHOICE ||
                this.selected_quiz_board_type === QuizBoardType.QUESTION_MULTIPLE_CHOICE
            ) {
                const QB = this.quizBoards.find(it => it._id == this.lv.subject);
                if (QB) {
                    for (let i = 0; i < QB.answers.length; i++) {
                        A.push({
                            text: QB.answers[i].text,
                            value: QB.answers[i]._id
                        });
                    }
                }
            }

            return A;
        }
    },

    watch: {
        "value": {
            handler() {
                if (this.value) {
                    this.lv = {
                        ...this.value
                    };

                    if (this.subject_type === "") {
                        this.$nextTick(() => {
                            if (!this.lv.subject || REGEXES.MONGO_ID.test(this.lv.subject)) {
                                this.subject_type = "quiz_board";
                            } else {
                                this.subject_type = "chart_cv";
                            }
                        });
                    }
                }
            },
            immediate: true
        },

        "subject_type"(nv, ov) {
            if (!nv || !ov) return;
            this.handleSubjectTypeChange();
        },
        "lv.subject"(nv, ov) {
            if (!nv || !ov) return;
            this.handleSubjectChange();
        }
    },

    methods: {
        onExpTypeChange() {
            if (this.multiple_answers_allowed) {
                this.lv.answer = [];
            } else {
                this.lv.answer = "";
            }
            this.clearAnswerFieldErrors();
            this.emitChange();
        },

        clearAnswerFieldErrors() {
            if (this.$refs.answer_tfield != undefined) {
                this.$refs.answer_tfield.resetValidation();
            }
            if (this.$refs.answer_select != undefined) {
                this.$refs.answer_select.resetValidation();
            }
        },

        handleSubjectTypeChange() {
            if (this.subject_type === "chart_cv") {
                this.lv.answer = "";
                this.lv.subject = "";
                this.clearAnswerFieldErrors();
                this.emitChange();
            } else {
                const NB = this.quizBoards.find(it => it._id == this.lv.subject);
                if (NB) {
                    if (this.multiple_answers_allowed) {
                        this.lv.answer = [];
                    } else {
                        this.lv.answer = "";
                    }
                    this.clearAnswerFieldErrors();
                    this.emitChange();
                }
            }
        },
        handleSubjectChange() {
            if (this.subject_type === "quiz_board") {
                const NB = this.quizBoards.find(it => it._id == this.lv.subject);
                if (NB) {
                    if (this.multiple_answers_allowed) {
                        this.lv.answer = [];
                    } else {
                        this.lv.answer = "";
                    }
                    this.clearAnswerFieldErrors();
                    this.emitChange();
                }
            }
        },

        validate() {
            const A = [];

            A.push(this.$refs.subject_select.validate(true));
            A.push(this.$refs.exp_type_select.validate(true));

            if (this.$refs.answer_select != undefined) {
                A.push(this.$refs.answer_select.validate(true));
            }
            if (this.$refs.answer_tfield != undefined) {
                A.push(this.$refs.answer_tfield.validate(true));
            }

            return A.indexOf(false) === -1;
        },

        emitChange() {
            this.$emit("input", this.lv);
        }
    }
};
</script>
