import { StorageSerializers, useLocalStorage } from "@vueuse/core";
import { UserRole } from "../../enums/User";
import router from "../../router";
import { StorageKeys } from "../../enums/General";
import { isAuthDataValid } from "../../helpers/auth-data";

export default {
    namespaced: true,

    state: () => ({
        user: useLocalStorage(StorageKeys.AUTH_USER, null, {
            serializer: StorageSerializers.object
        }),
        auth_data: useLocalStorage(StorageKeys.AUTH_AD, null, {
            serializer: StorageSerializers.object
        }),
        user_permissions: useLocalStorage(StorageKeys.AUTH_USER_PERMISSIONS, [], {
            serializer: StorageSerializers.object
        }),

        refreshing_auth_data_promise: null,
        socket_room_joined: false,
        socket_reconnect_tries: 0,

        logout_promise: null
    }),

    getters: {
        isLoggedIn(state) {
            return state.auth_data != null && state.auth_data !== undefined;
        },
        authData(state) {
            return state.auth_data;
        },
        getUser(state) {
            return state.user;
        },

        // PERMISSIONS
        userPermissions(state) {
            return state.user_permissions;
        },
        userHasPermissions: state => permissions => {
            if (typeof permissions == "string") permissions = [permissions];
            let has_all = true;
            for (let i = 0; i < permissions.length; i++) {
                const ix = state.user_permissions.indexOf(permissions[i]);
                if (ix === -1) {
                    has_all = false;
                    break;
                }
            }
            return has_all;
        },

        // MISC
        userInitials: state => user => {
            if (!user) user = state.user;
            if (!user) return "?";

            let i = "";

            if (user.first_name) {
                i += user.first_name.slice(0, 1);
            }
            if (user.last_name) {
                i += user.last_name.slice(0, 1);
            }

            return i;
        },
        userFirstName: state => user => {
            if (!user) user = state.user;
            if (!user) return "?";

            return user.first_name;
        },
        userTimezone: state => user => {
            if (!user) user = state.user;
            if (!user) return "Europe/Warsaw";
            if (user.settings.timezone_select_automatically === true) {
                return user.settings.timezone_auto_assigned;
            }
            return user.settings.timezone_manually_selected;
        }
    },
    mutations: {
        setAuthData(state, data) {
            if (
                !data.refresh_token ||
                !data.refresh_token_exp_date ||
                !data.access_token ||
                !data.access_token_exp_date
            ) {
                throw new Error("Invalid AuthData Object");
            }

            state.auth_data = {
                access_token: data.access_token,
                access_token_exp_date: data.access_token_exp_date,
                refresh_token: data.refresh_token,
                refresh_token_exp_date: data.refresh_token_exp_date
            };
        },
        unsetAuthData(state) {
            state.auth_data = null;
        },

        setUser(state, data) {
            state.user = data;
        },
        unsetUser(state) {
            state.user = null;
        },

        setUserPermissions(state, data) {
            state.user_permissions = data;
        },
        unsetUserPermissions(state) {
            state.user_permissions = [];
        }
    },
    actions: {
        fetchUserData({ commit, state }) {
            return new Promise(async (resolve, reject) => {
                if (state.auth_data === null)
                    return reject("[auth/fetchUserData] User is not logged in");

                try {
                    const r = await this._vm.$axios.$get(`/users/me`);

                    if (r.user.role !== UserRole.ADMIN) {
                        this.dispatch("auth/logOut", {
                            alert_msg:
                                "Posiadasz niewystarczający poziom uprawnień do skorzystania z tej aplikacji",
                            alert_type: "error"
                        });
                    }

                    commit("setUser", r.user);
                    commit("setUserPermissions", r.user_permissions);

                    return resolve(true);
                } catch (err) {
                    return reject(err);
                }
            });
        },

        logIn({ commit, dispatch }, auth_data) {
            return new Promise(async (resolve, reject) => {
                try {
                    commit("setAuthData", auth_data);
                    dispatch("sendAuthHeaderToServiceWorker", auth_data.access_token);

                    if (!this._vm.$io.connected) {
                        this._vm.$io.connect();
                    }
                    await dispatch("fetchUserData");
                    dispatch("socketJoinRooms");

                    return resolve(true);
                } catch (err) {
                    return reject(err);
                }
            });
        },
        logOut({ commit, state }, data = {}) {
            if (state.logout_promise !== null) {
                return state.logout_promise;
            }

            state.logout_promise = new Promise(async resolve => {
                if (!state.auth_data) return;

                try {
                    await this._vm.$axios.$post("/auth/revoke-token", {
                        refresh_token: state.auth_data.refresh_token,
                        ...data
                    });
                } catch (err) {
                    console.error(err);
                }

                commit("unsetAuthData");
                commit("unsetUser");
                commit("unsetUserPermissions");
                this.commit("fetchedElements/nuke");
                this.commit("helpdesk/nuke");

                if (this._vm.$io.connected) {
                    this._vm.$io.disconnect();
                }

                if (router.app.$route.name != "auth-login") {
                    router.push({
                        name: "auth-login"
                    });
                }

                this.dispatch("addMessage", {
                    type: data.alert_type ? data.alert_type : "success",
                    msg: data.alert_msg ? data.alert_msg : "Wylogowano pomyślnie"
                });

                state.logout_promise = null;
                return resolve(true);
            });

            return state.logout_promise;
        },

        refreshAuthData({ commit, state, dispatch }, data) {
            if (state.refreshing_auth_data_promise !== null) {
                return state.refreshing_auth_data_promise;
            }

            state.refreshing_auth_data_promise = new Promise(async resolve => {
                try {
                    const r = await this._vm.$axios.$post("/auth/refresh-token", {
                        ...data
                    });

                    if (r && r.success == true) {
                        // console.log("AT REFRESHED!!");
                        commit("setAuthData", r.data);
                        dispatch("sendAuthHeaderToServiceWorker", r.data.access_token);
                        dispatch("socketJoinRooms");

                        setTimeout(() => {
                            state.refreshing_auth_data_promise = null;
                        }, 1000);
                        return resolve(true);
                    }

                    return resolve(false);
                } catch (err) {
                    console.error(err);

                    commit("unsetAuthData");
                    dispatch("logOut", {
                        alert_msg: "Nie udało się przedłużyć Twojej sesji",
                        alert_type: "error"
                    });
                    state.refreshing_auth_data_promise = null;
                    return resolve();
                }
            });
            return state.refreshing_auth_data_promise;
        },

        sendAuthHeaderToServiceWorker({ rootState, dispatch }, access_token) {
            if (rootState.service_worker_available) {
                dispatch("awaitForServiceWorkerActivation", null, { root: true }).then(() => {
                    if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
                        navigator.serviceWorker.controller.postMessage({
                            type: SW_CUSTOM_MESSAGES.AUTH_HEADER,
                            auth_header: `Bearer ${access_token}`
                        });
                    }
                });
            }
        },

        socketJoinRooms({ state }) {
            if (state.socket_room_joined === true || !state.auth_data) return false;

            if (!isAuthDataValid(state.auth_data)) {
                console.log(`[Socket.io] Invalid auth_data - login supressed`);
                return;
            }
            if (state.auth_data.access_token_exp_date - 60 * 1000 < Date.now()) {
                console.log(`[Socket.io] Expired access_token - login supressed`);
                return;
            }

            this._vm.$io.emit(
                "login",
                {
                    access_token: `Bearer ${state.auth_data.access_token}`
                },
                _ => {
                    console.log(_.msg);
                    if (_.success === true) state.socket_room_joined = true;
                    else {
                        if (state.socket_reconnect_tries < 1000) {
                            state.socket_reconnect_tries += 1;

                            const DELAY_SEC = Math.min(
                                10,
                                Math.round(
                                    state.socket_reconnect_tries *
                                        state.socket_reconnect_tries *
                                        0.02
                                ) + 1
                            );

                            console.log(`[Socket.io] Delaying auth for ${DELAY_SEC}s`);
                            setTimeout(() => {
                                this.dispatch("auth/socketJoinRooms");
                            }, DELAY_SEC * 1000);
                        }
                    }
                }
            );
        }
    }
};
