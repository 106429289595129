var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-autocomplete',{attrs:{"value":_vm.value,"outlined":"","loading":_vm.loading,"items":_vm.users,"search-input":_vm.search_string,"hide-no-data":!_vm.searched_at_all,"item-value":"_id","item-text":_vm.getItemText,"label":_vm.label,"cache-items":"","clearable":_vm.clearable,"disabled":_vm.disabled,"dense":_vm.dense,"hide-details":_vm.hideDetails,"background-color":"white"},on:{"input":_ => {
                _vm.$emit('input', _);
                _vm.$emit('change', _);
            },"update:searchInput":function($event){_vm.search_string=$event},"update:search-input":function($event){_vm.search_string=$event}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('div',{staticClass:"py-2"},[_c('UserDetailedTile',{attrs:{"id":item._id,"profile-image":item.profile_image ? item.profile_image.url_sm : '',"phone":{
                        phone_cc: item.phone_cc,
                        phone: item.phone
                    },"name":`${item.first_name} ${item.last_name}`,"email":item.email,"avatar-size":24,"copyable":false}})],1)]}},{key:"selection",fn:function({ attrs, item }){return [_c('div',{staticClass:"pt-1"},[_c('v-chip',_vm._b({staticClass:"ma-0 mr-2",attrs:{"color":"grey lighten-3","small":_vm.dense}},'v-chip',attrs,false),[(
                            item.profile_image &&
                            item.profile_image.url_xs &&
                            item.profile_image.url_xs != ''
                        )?_c('v-avatar',{attrs:{"left":""}},[_c('v-img',{attrs:{"src":item.profile_image.url_xs}})],1):_vm._e(),_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name ? item.last_name : "")+" "),(_vm.$store.state.auth.user && _vm.$store.state.auth.user._id == item._id)?_c('span',{staticClass:"ml-1"},[_vm._v(" (Ty) ")]):_vm._e()],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }