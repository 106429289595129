var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.lv != null)?_c('div',{staticClass:"expressions-wizard-condition pa-2 grey lighten-4 rounded"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"outlined":"","dense":"","label":"Źródło danych","items":[
                    {
                        text: 'Odpowiedź na pytanie',
                        value: 'quiz_board'
                    },
                    {
                        text: 'Wartość wykresu',
                        value: 'chart_cv'
                    }
                ],"hide-details":"auto"},on:{"change":_vm.emitChange},model:{value:(_vm.subject_type),callback:function ($$v) {_vm.subject_type=$$v},expression:"subject_type"}}),(_vm.subject_type === 'quiz_board')?_c('v-select',{ref:"subject_select",staticClass:"mt-2",attrs:{"outlined":"","dense":"","label":"Plansza","items":_vm.quiz_board_select_items,"hide-details":"auto","rules":[
                    v => {
                        if (v === '' || v == null) {
                            return 'Wybierz planszę, której ma dotyczyć warunek';
                        }
                        return true;
                    }
                ]},on:{"change":_vm.emitChange},model:{value:(_vm.lv.subject),callback:function ($$v) {_vm.$set(_vm.lv, "subject", $$v)},expression:"lv.subject"}}):(_vm.subject_type === 'chart_cv')?_c('v-text-field',{ref:"subject_select",staticClass:"mt-2",attrs:{"outlined":"","dense":"","label":"Tag wykresu","hide-details":"auto","rules":[
                    v => {
                        if (!v || !_vm.REGEXES.QUIZ_CHART_CV_TAG_REGEX.test(v)) {
                            return 'Podany tag nie spełnia wymagań składniowych';
                        }
                        return true;
                    }
                ]},on:{"input":_vm.emitChange},model:{value:(_vm.lv.subject),callback:function ($$v) {_vm.$set(_vm.lv, "subject", $$v)},expression:"lv.subject"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{ref:"exp_type_select",attrs:{"outlined":"","dense":"","label":"Warunek","items":_vm.condition_select_items,"hide-details":"auto","rules":[
                    v => {
                        if (v === '' || v == null) {
                            return 'Wybierz warunek';
                        }
                        return true;
                    }
                ]},on:{"change":() => {
                        _vm.onExpTypeChange();
                        _vm.emitChange();
                    }},model:{value:(_vm.lv.exp_type),callback:function ($$v) {_vm.$set(_vm.lv, "exp_type", $$v)},expression:"lv.exp_type"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[(_vm.predefined_answers_qbtypes.indexOf(_vm.selected_quiz_board_type) !== -1)?_c('v-select',{ref:"answer_select",attrs:{"outlined":"","dense":"","label":"Wartość","hide-details":"auto","multiple":_vm.multiple_answers_allowed,"items":_vm.answers_select_items,"rules":[
                    v => {
                        if (_vm.multiple_answers_allowed) {
                            if (v.length === 0) {
                                return 'Wybierz przynajmniej jedną opcję';
                            }
                        } else {
                            if (v === '' || v == null) {
                                return 'Wybierz jedną z opcji';
                            }
                        }

                        return true;
                    }
                ]},on:{"change":_vm.emitChange},model:{value:(_vm.lv.answer),callback:function ($$v) {_vm.$set(_vm.lv, "answer", $$v)},expression:"lv.answer"}}):_c('v-text-field',{ref:"answer_tfield",attrs:{"outlined":"","dense":"","label":"Wartość","hide-details":"auto","rules":[
                    v => {
                        if (v === '') {
                            return 'To pole nie może być puste';
                        }
                        if (v.length > 31) {
                            return 'Maksymalna długość pola to 31 znaków';
                        }

                        return true;
                    }
                ]},on:{"input":_vm.emitChange},model:{value:(_vm.lv.answer),callback:function ($$v) {_vm.$set(_vm.lv, "answer", $$v)},expression:"lv.answer"}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }