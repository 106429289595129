<template>
    <div class="expressions-wizard">
        <div class="text-body-1 mb-2">
            <v-btn
                plain
                color="grey"
                small
                class="mr-1 pa-0"
                v-if="step > 1"
                @click="goBackToFirstStep(false)"
            >
                <v-icon
                    size="16"
                    class="mb-1"
                    >mdi-arrow-left</v-icon
                >
                Zmień rodzaj wyrażenia
            </v-btn>
            <div class="text-h6 font-weight-regular">
                <template v-if="step === 1"> Wybór rodzaju wyrażenia </template>
                <template v-else-if="step === 2"> Złożone wyrażenie logiczne </template>
                <template v-else-if="step === 3"> Bezwarunkowe wykonanie akcji </template>
            </div>
        </div>

        <!-- KROK 1. - Wybór czy dodajemy if(true), czy bardziej złożony warunek -->
        <template v-if="step === 1">
            <v-row dense>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <div class="pa-4 rounded grey lighten-4 text-center">
                        <div class="text-body-2">Złożone wyrażenie logiczne</div>
                        <v-btn
                            small
                            color="primary"
                            class="mt-2"
                            @click="setupStatement()"
                        >
                            Wybierz
                        </v-btn>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <div class="pa-4 rounded grey lighten-4 text-center">
                        <div class="text-body-2">Bezwarunkowe wykonanie akcji</div>
                        <v-btn
                            small
                            color="primary"
                            class="mt-2"
                            @click="setupStatement(true)"
                        >
                            Wybierz
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </template>

        <!-- KROK 2.1. - Złożony warunek if-else (rekurencja) -->
        <ExpressionsWizardComplexIfCreator
            v-if="step === 2"
            v-model="ifcomplex_ds"
            :num="1"
            :nesting-limit="nestingLimit"
            :elseif-limit="elseifLimit"
            :quiz-boards="quizBoards"
            :rendercontentflow-board-id="rendercontentflowBoardId"
            :allowed-actions="allowedActions"
            @input="emitData"
            ref="complex_if_wizard"
        />

        <!-- KROK 2.2. - if(true) - definicja samej akcji  -->
        <ExpressionsWizardActionSelector
            v-if="step === 3"
            :quiz-boards="quizBoards"
            :allowed-actions="allowedActions"
            :rendercontentflow-board-id="rendercontentflowBoardId"
            v-model="iftrue_ds.then"
            @input="emitData"
            ref="iftrue_action_selector"
        />
    </div>
</template>

<script>
import ExpressionsWizardActionSelector from "./ExpressionsWizardActionSelector.vue";
import ExpressionsWizardComplexIfCreator from "./ExpressionsWizardComplexIfCreator.vue";

export default {
    components: {
        ExpressionsWizardActionSelector,
        ExpressionsWizardComplexIfCreator
    },

    props: {
        allowedActions: {
            type: Array,
            default() {
                return ["gotoboard", "rendercontentflow", "plaintext"];
            }
        },
        elseifLimit: {
            type: Number,
            default: 16
        },
        nestingLimit: {
            type: Number,
            default: 5
        },
        quizBoards: {
            required: true,
            type: Array
        },
        rendercontentflowBoardId: {
            type: String,
            default: ""
        },
        value: {
            type: String,
            default: ""
        }
    },

    data() {
        return {
            step: 1,
            iftrue_ds: null,
            ifcomplex_ds: null
        };
    },

    watch: {
        value: {
            handler() {
                if (!this.value || this.value === "") return;
                const PD = JSON.parse(this.value);
                if (PD.if === true) {
                    this.iftrue_ds = {
                        entity_type: "ifblock",
                        if: true,
                        then: PD.then && typeof PD.then === "string" ? PD.then : ""
                    };
                    this.step = 3;
                } else {
                    this.ifcomplex_ds = {
                        ...PD
                    };
                    this.step = 2;
                }
            },
            immediate: true
        }
    },

    methods: {
        setupStatement(iftrue_mode = false) {
            if (iftrue_mode) {
                if (this.iftrue_ds === null) {
                    this.iftrue_ds = {
                        entity_type: "ifblock",
                        if: true,
                        then: ""
                    };
                }
                this.step = 3;
                this.emitData();
            } else {
                if (this.ifcomplex_ds === null) {
                    this.ifcomplex_ds = {
                        entity_type: "ifblock",
                        if: null,
                        then: "",
                        else: null
                    };
                }
                this.step = 2;
                this.emitData();
            }
        },

        goBackToFirstStep() {
            this.reset();
        },
        reset() {
            this.ds = null;
            this.step = 1;
        },

        validate() {
            // if(true)
            if (this.step === 3) {
                return this.$refs.iftrue_action_selector.validate();
            }
            // ifcomplex
            else if (this.step === 2) {
                return this.$refs.complex_if_wizard.validate();
            } else {
                this.$message({
                    type: "error",
                    msg: "Wybierz rodzaj wyrażenia"
                });
                return false;
            }
        },
        emitData() {
            this.$emit(
                "input",
                JSON.stringify(this.step === 2 ? this.ifcomplex_ds : this.iftrue_ds)
            );
        }
    }
};
</script>
